// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
//$2a$10$goGEnbGcgns1uOM2MUwVQ.wMd7WYS0CozHtXQAX923vu0uU42nipK
// clave

export const environment = {
  production: false,
  hmr: false,
  uriGraphql: "https://pha.thomas-talk.me/",
  subsGraphql: "wss://pha.thomas-talk.me/subscriptions",
  ServerUploadFile: "https://upload.thomas-talk.me",
  serverImageBase64: 'https://img.thomas-talk.me/api/', //https://img.thomas-talk.tk/api/', http://localhost:5056/api/
  urlApiSaldo: 'https://api.thomas-talk.me/SaldoCe/',
  urlApiWsp: 'https://wa.thomas-talk.me/',
  urlApiEstadoDeCuenta: 'https://api.thomas-talk.me/GenerateEdoCuenta/',
  urlApi_SSL: 'https://apissl.thomas-talk.me/',
  urlApi_TLS:'https://apitls.fibextelecom.net/',
  urlApi_TLSinfo:'https://apitls.fibextelecom.info/',
  urlFactura_digital: /* 'https://facturadig.thomas-talk.me/' */  'http://localhost:9007/',
  firebaseConfig: {
    apiKey: "AIzaSyD8u2wPPFAR0V0TyKpHiikfw_ZWnzjKs7Q",
    authDomain: "thomas-v9.firebaseapp.com",
    databaseURL: "https://thomas-v9-default-rtdb.firebaseio.com",
    projectId: "thomas-v9",
    storageBucket: "thomas-v9.appspot.com",
    messagingSenderId: "73649914330",
    appId: "1:73649914330:web:523950baf0b4f2a4de40e3",
    measurementId: "G-CVEBGCGLG0"
  },
  akssl: '4p1_[51t3m4s]_f186@th0m4s',
  methodGeneratePin: 'GenerarPin',
  platform: 'Thomas_v11',
  campanasShow: false,
  citasShow: false,
  tareasShow: false,
  proyectosShow: false,
  reportesShow: true,
  mercadeoShow: false,
  planesShow: false,
  pagosShow: false,
  KeyEncript: "",
  urlDBFULL: "https://backend.thomas-talk.me/api/v1/",
  database: "",
  tablaUsers:"",
  tokenAuth:"",
  AuthDbFull:"",
  version: "V11.3.15 - THE FACTORY",
  ApiFactura: "https://facturadig.thomas-talk.me/Envio"
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

// Initialize Firebase
// firebase.initializeApp(firebaseConfig);
// firebase.analytics();







