import { FuseNavigation } from '@fuse/types';
import { environment } from 'environments/environment'

export const navigation: FuseNavigation[] = [
    {
        id: 'applications ',
        title: 'Aplicación',
        translate: 'NAV.APLICACIÓN',
        type: 'group',
        icon: 'apps',
        children: [
            {
                //id       : 'dashboards',
                id: '1',
                title: 'Inicio',
                translate: 'NAV.DASHBOARDS',
                type: 'collapsable',
                icon: 'dashboard',
                children: [
                    {
                        id: 'project',
                        title: 'Control',
                        translate: 'NAV.Control',
                        type: 'item',
                        url: '/apps/dashboards/project'
                    }//,
                    // //!DASHBOARDv2
                    // {
                    //     id   : 'projectv2',
                    //     title: 'ControlV2',
                    //     translate: 'NAV.Control',
                    //     type : 'item',
                    //     url  : '/apps/dashboards/projectv2'
                    // }, 
                    /*{
                        id   : 'analytics',
                        title: 'Resumen',
                        type : 'item', 
                        url  : '/apps/dashboards/analytics'
                    }      */
                ]
            },
            {
                //id       : 'Clientes',
                id: '2',
                title: 'Clientes',
                translate: 'NAV.Cliente',
                type: 'item',
                icon: 'person',
                url: '/apps/e-commerce/products',
                badge: {
                    title: '0',
                    bg: '#09d261',
                    fg: '#FFFFFF'
                }
            },
            environment.campanasShow ? {
                //id       : 'calendar',
                id: '3',
                title: 'Campaña',
                translate: 'NAV.CALENDAR',
                type: 'item',
                icon: 'today',
                url: '/apps/calendar'
            } : {
                id: '',
                title: '',
                translate: '',
                type: 'item',
                icon: '',
                url: ''
            },
            environment.citasShow ? {
                id: 'citas',
                title: 'Citas',
                translate: 'NAV.Citas',
                type: 'item',
                icon: 'today',
                url: '/apps/citas'
            } : {
                id: '',
                title: '',
                translate: '',
                type: 'item',
                icon: '',
                url: ''
            },
            {
                //id       : 'chat',
                id: '4',
                title: 'Chat-1',
                translate: 'NAV.CHAT',
                type: 'item',
                icon: 'chat',
                url: '/apps/chat/version-1',
                badge: {
                    title: '0',
                    bg: '#09d261',
                    fg: '#FFFFFF'
                }
            },
            {
                id: '18',
                title: 'Chat-2',
                translate: 'NAV.CHAT',
                type: 'item',
                icon: 'chat',
                url: '/apps/chat2/version-2',
                badge: {
                    title: '0',
                    bg: '#09d261',
                    fg: '#FFFFFF'
                }
            },
            {
                id: '12',
                //title: 'Importar archivo',
                title: 'Generador Pin',
                //translate: 'NAV.IMPORTACION',
                translate: 'NAV.GENERADORPIN',
                type: 'item',
                icon: 'lock',
                url: '/pages/generadorpin',
                //url: '/pages/importacion',

            },
            {
                id: '14',
                title: 'Procesado de txt',
                translate: 'NAV.PROCESSTXT',
                type: 'item',
                icon: 'lock',
                url: '/pages/processtxt',

            },
            // {
            //     id: '20',
            //     title: 'Importar Archivo',
            //     translate: 'NAV.IMPORTACION',
            //     type: 'item',
            //     icon: 'lock',
            //     url: '/pages/importacion',
            // },
            environment.tareasShow ? {
                //id       : 'to-do',
                id: '5',
                title: 'Tareas',
                translate: 'NAV.TODO',
                type: 'item',
                icon: 'check_box',
                url: '/apps/todo',
                badge: {
                    title: '0',
                    bg: '#FF6F00',
                    fg: '#FFFFFF'
                }
            } : {
                id: '',
                title: '',
                translate: '',
                type: 'item',
                icon: '',
                url: ''
            },
            environment.proyectosShow ? {
                //id       : 'scrumboard',
                id: '6',
                title: 'Proyectos',
                translate: 'NAV.SCRUMBOARD',
                type: 'item',
                icon: 'assessment',
                url: '/apps/scrumboard'
            } : {
                id: '',
                title: '',
                translate: '',
                type: 'item',
                icon: '',
                url: ''
            },
            environment.reportesShow ? {
                //id       : 'Reportes',
                id: '7',
                title: 'Reportes',
                translate: 'NAV.REPORTES',
                type: 'item',
                icon: 'receipt',
                url: '/reportes/cards'
            } : {
                id: '',
                title: '',
                translate: '',
                type: 'item',
                icon: '',
                url: ''
            }
        ]
    },
    {
        id: 'user-interface',
        title: 'Administración',
        translate: 'NAV.ADMINISTRACION',
        type: 'group',
        icon: 'web',
        children: [
            {
                //id   : 'Agentes',
                id: '8',
                title: 'Agentes',
                translate: 'NAV.Agentes',
                type: 'item',
                icon: 'people',
                url: '/apps/agent',
                badge: {
                    title: '0',
                    //bg   : '#039be5',
                    bg: '#EC0C8E',
                    fg: '#FFFFFF'
                }
            },
            {
                //id   : 'Agentes',
                id: '19',
                title: 'Facturación',
                translate: 'NAV.Billing',
                type: 'item',
                icon: 'receipt_long',
                url: '/apps/billing',
                /* badge: {
                    title: '0',
                    //bg   : '#039be5',
                    bg   : '#EC0C8E',
                    fg   : '#FFFFFF'
                } */
            },
            {
                //id   : 'Contactos',
                id: '9',
                title: 'Contactos',
                translate: 'NAV.CONTACTS',
                type: 'item',
                icon: 'adb',
                url: '/apps/contactos',
                badge: {
                    title: '0',
                    bg: '#EC0C8E',
                    fg: '#FFFFFF'
                }
            },
            {
                //id   : 'Tecnicos',
                id: '16',
                title: 'Técnicos',
                type: 'item',
                icon: 'supervisor_account',
                url: '/apps/tecnicos',
                badge: {
                    title: '0',
                    bg: '#EC0C8E',
                    fg: '#FFFFFF'
                }
            },
            {
                //id   : 'Roles',
                id: '10',
                title: 'Roles',
                type: 'item',
                icon: 'color_lens',
                url: '/apps/roles',
                badge: {
                    title: '0',
                    bg: '#EC0C8E',
                    fg: '#FFFFFF'
                }
            },
            {
                //id       : 'file-manager',
                id: '11',
                title: 'Galeria',
                translate: 'NAV.FILE_MANAGER',
                type: 'item',
                icon: 'folder',
                url: '/apps/file-manager',
                badge: {
                    title: '0',
                    bg: '#EC0C8E',
                    fg: '#FFFFFF'
                }
            },
            environment.mercadeoShow ? {
                id: 'maps-contact',
                title: 'Mercadeo',
                translate: 'NAV.FILE_MANAGER',
                type: 'item',
                icon: 'map',
                url: '/pages/maps-contact'
            } : {
                id: '',
                title: '',
                translate: '',
                type: 'item',
                icon: '',
                url: ''
            },
            environment.planesShow ? {
                //id   : 'Planes',
                id: '13',
                title: 'Planes y Pagos',
                type: 'item',
                icon: 'call_to_action',
                url: '/apps/e-commerce/pagos',
            } : {
                id: '',
                title: '',
                translate: '',
                type: 'item',
                icon: '',
                url: ''
            },

        ]
    },
    {
        id: 'pages',
        title: 'Integradores',
        translate: 'NAV.INTEGRADORES',
        type: 'group',
        icon: 'pages',
        children: [
            {
                //id   : 'faq',
                id: '15',
                title: 'Preguntas',
                translate: 'NAV.Preguntas',
                type: 'item',
                icon: 'help',
                url: '/pages/faq'
            },
            {
                //id   : 'knowledge-base',
                id: '17',
                title: 'Novedades',
                translate: 'NAV.Novedades',
                type: 'item',
                icon: 'import_contacts',
                url: '/pages/knowledge-base'
            },
            {
                //id   : 'knowledge-base',
                id: '18',
                title: 'Entrenamiento',
                translate: 'NAV.Entrenamiento',
                type: 'item',
                icon: 'slideshow',
                url: '/pages/entrenamiento'
            }
        ]
    },

];
