import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ValidationsService {

  constructor() { }

  public TextShowList(contentType: string, content: any) {
    var Salida: string = ""
    switch (contentType) {
      case "0":
        try {
          if (content != undefined) 
            if (typeof content == "string" ) Salida = content.length < 30 ? content: content.substring(0, 30) + "..."
            else Salida = ". . ."  
        } catch (error) {
          console.error("=========== ERROR CONTROLADO ============")          
          console.error(error)          
          console.error("=========== FIN ERROR CONTROLADO ============")          
          Salida = "Error..."  
        }        
        break;
      case "1":
        Salida = "Imagen..."  
        break;
      case "4":
        Salida = "File PDF..."  
        break;
      case "8":
        Salida = "File GIF..."  
        break;
      case "9":
        Salida = "File Sticker..."  
        break;
      case "6":
        Salida = "Close case. . ."  
        break;
      default:
        if (content != undefined) Salida = content.length < 30 ? content: content.substring(0, 30) + "..."
          else Salida = ". . ."  
        break;
    }
    return Salida
  }

  validateArroba(lic: any, opcion:any){
     if (lic) {
        let validar= lic.includes('@c.us')
      switch (opcion) {
        case "add":

              if (!validar) {
                  lic=lic+'@c.us'
              }
              return lic

        case "delete":
              if (validar) {
                  lic=lic.replace("@c.us",'')
              }
              return lic

        default:
          break;
      }

     } else {
       console.log("No pase por el IF, MUY DELICADO ESO")
       console.log(lic)
     }
     
  }

  //Crea una connversacion nueva con la fecha actual
  createIDbyLicPhone(lic,phone){
    lic =lic.replace("@c.us","")
    phone =phone.replace("@c.us","")
        let hoy = new Date(Date.now()).toISOString().substring(0, 10);
        hoy = hoy.replace("1-","1_")
        const Raya = "_"
        const idConversacion = lic + Raya + phone + Raya + hoy
        return idConversacion
}//Esto pone la fecha de hoy, asi que crea un chat nuevo

  //Falta una funcion que me tome la fecha del chat 


}
