export const locale = {
    lang: 'es',
    data: {
        'NAV': {
            'APLICACIÓN': 'Aplicación',
            'DASHBOARDS': 'Inicio',
            'ADMINISTRACION': 'Administración',
            'INTEGRADORES': 'Integradores',
            'CUSTOMFUNCTION': 'Función Personalizada',
            'CUSTOMIZE': 'Personalizar',
            'CALENDAR': 'Campañas',
            'ECOMMERCE': 'E-Commerce',
            'MAIL': {
                'TITLE': 'Mail',
                'BADGE': '25'
            },
            'MAIL_NGRX': {
                'TITLE': 'Mail Ngrx',
                'BADGE': '13'
            },
            'Control': 'Control',
            'CHAT': 'Chat',
            'GENERADORPIN': 'Generador Pin',
            'IMPORTACION': 'Importar Archivo',
            'Cliente': 'Cliente',
            'Agentes': 'Agentes',
            'Billing': 'Facturación',
            'FILE_MANAGER': 'Galería',
            'Configuracion': 'Configuración',
            'CONTACTS': 'Contactos',
            'Preguntas': 'Preguntas',
            'Novedades': 'Novedades',
            'Entrenamiento': 'Entrenamiento',
            'TODO': 'TODO',
            'REPORTES': 'Reportes',
            'PROCESSTXT': 'Procesado de txt'
        },
        Reportes: {
            'billingSection': 'Apartado de Facturación',
            'FacturasMasivas': 'Facturas Masivas',
            'AnularFacturas': 'Anular Facturas',
            'Invoice_Generated': 'Facturas Generadas',
            'Invoice_Consultation': 'Consulta de Facturas',
            'Invoice_Reversal': 'Anulación de Facturas',
            'FacturasMasivasDesc': 'Seleccione para realizar envíos de facturaciones masivas',
            'Invoice_GeneratedDesc': 'Seleccione para consultar facturas generadas',
            'Invoice_ConsultationDesc': 'Seleccione para consultar facturas',
            'Invoice_ReversalDesc': 'Seleccione para realizar anulaciones en facturas',
            'Clientes': 'Clientes',
            'Contactos': 'Contactos',
            'Search': 'Buscar un',
            'Nombre': 'Nombre',
            'Avater': 'Logo',
            'Agentes': 'Agentes',
            'Estatus': 'Estatus',
            'Cola': 'Cola',
            'Menú': 'Menú',
            'Cargar + Contactos': 'Cargar + Contactos',
            'avatar': 'Foto',
            'Avatar': 'Foto',
            'name': 'Nombre',
            'phone': 'Teléfono',
            'Canal': 'Canal',
            'menú': 'Menú',

            'Agregar': 'Agregar',
            'transfer': 'Transferir',
            'online': 'En línea',
            'lastConexion': 'Ultima conexión',
            'Roles': 'Roles',
            'Rol': 'Permisos',
            'Descripción': 'Descripción',
            'Acción': 'Acción',

            'url': 'Url',
            'title': 'Título',
            'owner': 'Propietario',
            'modified': 'Modificado',
            'menu': 'Menú',
            'Galeria': 'Galería',
            'Enter': 'Presione enter',
            'Exportar': 'Exportar',
            'NoData': 'No hay datos disponibles, seleccione un archivo o ingrese un nuevo dato.',


            'Técnicos': 'Técnicos',
            'Apellido': 'Apellido',
            'Teléfono': 'Teléfono',

            'BusquedaAvanzada': 'Busqueda Avanzada',
            'Contacto': 'Contacto',
            'TextoDelMensaje': 'Texto del mensaje',
            'TipoDeMensaje': 'Tipo de mensaje',
            'ContenidoMensaje': 'Contenido del mensaje',
            'Telefono': 'Teléfono',
            'Etiqueta': 'Etiqueta',
            'FechaDeIncio': 'Fecha de incio',
            'FechaDeFin': 'Fecha de fin',
            'Entrante': 'Entrante',
            'Saliente': 'Saliente',
            'Todos': 'Seleccionar todos',
            'Generador': 'Generador',
            'Reportes': 'Reportes',
            'Buscar': 'Buscar',
            'Realizar nueva búsqueda': 'Realizar nueva búsqueda',
            'Encuestas': 'Encuestas',
            'TipoDeEncuesta': 'Tipo de encuesta',
            'EncuestasAgentes': 'Encuestas realizadas por el bot y los agentes.',
            'ReporteAgente': 'Reporte filtrado de todos los clientes por agente',

            'Texto': 'Texto',
            'Imagen': 'Imagen',
            'Video': 'Video',
            'Audio': 'Audio',
            'PDF': 'PDF',
            'Cierre': 'Cierre',
            'Transferir': 'Transferir',
            'Sticker': 'Sticker',

            'Consulta': 'La consulta debe ser en un rango maximo de 2 meses',
            'CanalBusqueda': 'Debes proporcionar un canal con el que debes realizar la busqueda',

            'Ciudad': 'Ciudad',
            'Email': 'Email',
            'Bravery Rating': 'Correo electrónico',
            'Fecha': 'Fecha',
            'First name': 'Nombre',
            'Michael': 'Michael',

            'Indicadores': 'Indicadores',
            'App': 'FibexApp',
            'Rendimiento': 'Rendimiento',
            'ConteRendi': 'Por medios de indicadores podrás llevar un control del rendimiento de los Agentes.',
            'Tiempos': 'Tiempos',
            'ConteTiempos': 'Podrás visualizar el tiempo de conexión de los agentes.',
            'Bots': 'AppFibex',
            'ConteBots': ' Reporte de los usuarios de Fibex que poseen la aplicación móvil.',
            'Fecha Inicial': 'Fecha inicial',
            'Fecha Final': 'Fecha Final',
            'Operador': 'Operador',
            'Licencias': 'Licencia',
            'Etiquetas': 'Etiquetas',

            'Totalmensajes': 'Total de mensajes',
            'Factura_Masiva': 'Facturas Masivas',

            'FECHA': 'Fecha',
            'CODIGO': 'Codigo',
            'CLIENTE': 'Cliente',
            'FACTURA': 'Factura',
            'CONTROL': 'Control',
            'BASE': 'Base',
            'IVA': 'IVA',
            'NETO': 'Neto',
            'BASEUSD': 'Base $',
            'IVAUSD': 'IVA $',
            'NETOUSD': 'Neto $',
            'DESCRIP': 'Descripcion',
            'ART': 'Articulo',
            'DIRECCION': 'Direccion',
            'IGTF': 'IGTF',
            'IGTFUSD': 'IGTF $',
            'TASABCV': 'Tasa BCV',
            'FECHATASA': 'Fecha BCV',
            'STATUS': 'Estatus',
            'FORMAPAGO': 'Forma Pago',
            'TIPOFACTURA': 'Tipo Factura',
            'FACTURAFECTADA': 'Factura Afectada',
            'URLFACTURA': 'Url Factura',
            'VARIOSITEM': 'Varios Items',
            'ITEM': 'Items',

        },
        ReportList: {
            'Agentes': 'Agentes',
            'Atajos': 'Atajos',
            'Abreviado': 'Abreviado',
            'Texto': 'Texto',
            'Canal': 'Canal',
            'Menú': 'Menú',
            'Móvil': 'Móvil',

            'Etiqueta': 'Etiqueta',
            'Operador': 'Operador',
            'Total': 'Total',

            'Campos': 'Campo',
            'Campo': 'Campo',
            'Tipo': 'Tipo',
            'Requerido': 'Requerido',
            'Ubicación': 'Ubicación',

            'Etiquetas': 'Etiquetas',

            'Horarios': 'Horarios',
            'Descripción': 'Descripción',
            'Jornadas': 'Jornadas',
            'DiaInicio': 'Inicio',
            'DiaFin': 'Fin',
            'Excepciones': 'Excepciones',

            'Contactos': 'Contactos',

            'Bot': 'Bot',
            'Avatar': 'Foto',
            'Nombre': 'Nombre',
            'Cola': 'Cola',
            'Online': 'En línea',


            'Mensajes': 'Mensajes',
            'Contacto': 'Contacto',
            'Cuenta': 'Cuenta',
            'Fecha Inicio': 'Fecha / Inicio',
            'Requerimiento': 'Requerimiento',
            'AgentesExclusivos': 'Agentes exclusivos',

            'Transferir': 'Transferir',
            'Ultima conexion': 'Última conexión',

            'Exportar': 'Exportar',
            'Agentes exclusivos': 'Agentes exclusivos',
            'Telefono': 'Teléfono',
            'Mensaje': 'Mensaje',

            'Fecha': 'Fecha',
            'Codigo': 'Código',
            'Cliente': 'Cliente',
            'Factura': 'Factura',
            'Control': 'Control',
            'Base': 'Base',
            'IVA': 'IVA',
            'Neto': 'Neto',
            'Descripcion': 'Descripción',
            'Articulo': 'Articulo',
            'Direccion': 'Direccion',

            'Reporte': 'Reportes',
            'TipoContenido': 'Tipo de Contenido',
            'Contenido': 'Contenido',
            'nombre': 'Nombre',
            'tipoDeFalla': 'Tipo de Falla',
            'banco': 'Banco',
            'tipoDeUsuario': 'Tipo de Usuario',
            'imagenes': 'Imagenes',
            'procesado': 'Procesado',
            'phone': 'Teléfono',
            'Filtro': 'Filtro',
            'Mesajes masivos': 'Mesajes masivos',
            'Licencia': 'Licencia',
            'Totalmensajes': 'Total de mensajes',
            'IGTF': 'IGTF',
            'IGTF $': 'IGTF $',
            'Tasa BCV': 'Tasa BCV',
            'Fecha BCV': 'Fecha BCV',
            'Estatus': 'Estatus',
            'Base $': 'Base $',
            'IVA $': 'IVA $',
            'Neto $': 'Neto $',
            'Forma Pago': 'Forma Pago',
            'Tipo Factura': 'Tipo Factura',
            'Factura Afectada': 'Factura Afectada',
            'Url Factura': 'Url Factura',
            'EXENTO': 'Exento',
            'BASE IGTF': 'Base IGTF'
        },
        Generico: {
            'Siguiente': 'Siguiente',
            'SIGUIENTE': 'SIGUIENTE',
            'Seleccionar': 'Seleccionar',
            'Atras': 'Atrás',
            'CANCELAR': 'CANCELAR',
            'AGREGAR': 'AGREGAR',
            'Aceptar': 'Aceptar',
            'GUARDAR': 'GUARDAR',
            'Cargandoo': 'CARGANDO',
            'CrearHorario': 'CREAR HORARIO',
            'Anterior': 'Anterior',
            'CrearUsuario': 'Crear Usuario',
            'GuardarCambios': 'Guardar Cambios',
            'Mensajes': 'Mensajes',
            'Agentes': 'Agentes',
            'WelcomeBack': 'Bienvenido de nuevo,',
            'Home': 'Inicio',
            'Delete': 'Eliminar',
            'Eliminar': 'Eliminar',
            'Add': 'Agregar',
            'Save': 'Guardar',
            'Guardar': 'Guardar',
            'Agregar': 'Agregar',
            'Back': 'Atrás',
            'Reset': 'Reiniciar',
            'Cancelar': 'Cancelar',
            'Details': 'Detalles',
            'Asignar': 'Asignar',
            'Cargando': ' CARGANDO...',
            'NoEncontroDatos': 'No se encontro Datos.',
            'RemoveList': 'Eliminar lista',
            'Cerrar': 'Cerrar',
            'SelectAll': 'Seleccionar todo',
            'DeselectAll': 'Deseleccionar todo',
            'De': ' de ',
            'Confirmar': 'Confirmar',
            'Days': 'Días',
            'Hours': 'Horas',
            'Minutes': 'Minutos',
            'Seconds': 'Segundos',
            'Ayer': 'Ayer',
            'Hoy': 'Hoy',

            'Editar': 'Editar',
            'CambiarClave': 'Cambiar Clave',
            'Chat ': 'Chat',
            'Chat': 'Chat',
            'Assign': 'ASIGNAR',

            'Ver': 'Ver',
            'Go': 'Entrar',
            'Cambiar Estado': 'Cambiar Estado',
            'Atendiendo': 'Atendiendo',
            'Reasignar': 'Reasignar',
            'edit': 'Editar',
            'delete': 'Eliminar',
            'vpn_key': 'Cambiar Clave',
            'ConoceMas': 'Conoce más',
            'Ver conversacion': 'Ver conversación',
            'Ver contenido': 'Ver contenido',
            'Visualizar': 'Visualizar',
            'Escanear': 'Escanear QR',
            'SolicitarPin': 'Generar Pin',
            "PinCliente": 'El PIN del cliente es',
            'DatosCliente': 'Datos del clientes',
            'archivoSms': 'Envia mensajes importando un archivo (Excel, CSV)'

        },
        DatosClientes: {
            'AjustesDeLaCuenta': 'Ajustes de la Cuenta',
            'NuevaCliente': 'Nueva Cliente',
            'BasicInfo': 'Información Básica',
            'Canales': 'Canales',
            'Galeria': 'Galería',
            'Contactos': 'Contactos',
            'Horarios': 'Horarios',
            'Etiquetas': 'Etiquetas',
            'Campos': 'Campos',
            'Atajos': 'Atajos',
            'NameEmp': 'Nombre de Empresa',
            'Rif': 'Rif',
            'Email': 'Correo',
            'Phone': 'Teléfono',
            'Prefijo': 'Prefijo',
            'Code': 'Código',
            'Direction': 'Dirección',
            'PaymetMethod': 'Método de Pagos',
            'MensajeAdicional': 'Mensaje Adicional',

            'Datos': 'Datos Guardado!',
            'DatosGuardados': 'Datos Guardado por favor salga y vuelvan entrar para refrescar los cambios',
            'ClienteAgregado': 'Cliente Agregado',
            'Error': 'Error',
            'ArchivoGuardado': 'Archivo Guardado:',
            'ErrorArchivo': 'Error al subir Archivo',
            'Eliminar': 'Deseas eliminar a ',

        },
        Canales: {
            'ChannelType': 'Tipos de canal',
            'MultiOperador': 'Multioperador',
            'CtrlWhatsApp': 'Control de Whatsapp Multi Agentes',
            'CtrlFacebook': 'Control de Facebook Multi Agentes',
            'NoDisponible': 'No disponible',
            'SignalAgen': 'Signal Multi Agentes',
            'TelegAgen': 'Telegram Multi Agentes',
            'ScannQR': 'Escaner QR',

            'Telegram': 'Telegram',
            'Facebook': 'Facebook',
            'Whatsapp': 'Whatsapp',
            'Signal': 'Signal',

            'ConfDeMensaj': 'Configuración de Mensajes',
            'LastnameFirstname': 'Nombre, Apellido',
            'MensajDeBienve': 'Mensaje de Bienvenida',
            'MensajDelContacto': 'Mensaje para Solicitar Nombre y Apellido del Contacto',
            'MensajCorreo': 'Mensaje para Solicitar Correo Electrónico del Contacto',
            'MensajDatos': 'Mensaje para Indicar que se ha Guardado los Datos Diligenciados',
            'MensajAgentes': 'Mensaje para Indicar la Asignación de un Agente a la Conversación',
            'MensajConvers': 'Mensaje para Indicar Finalización de una Conversación',
            'MensajDisponi': 'Mensaje para Indicar que no hay Agentes Disponibles',
            'MensajAlerta': 'Mensaje de Alerta al Contacto cuando se Detecta Inactividad en la Conversación',
            'MensajFinaliz': 'Mensaje para Indicar que la Conversación Finalizará por Inactividad del Contacto',
            'MensajArchivo': 'Mensaje para Indicarle al Contacto que no es Posible Recibir el Archivo',
            'MensajLlamada': 'Mensaje en caso de Llamadas',

            'PermiDelCanal': 'Permisos del Canal',
            'PermiConvers': 'Permitir Iniciar una Conversación con Contactos Desconocidos',
            'PermiNotif': 'Permitir Enviar Notificaciones',
            'PermiDifusiones': 'Permitir Enviar Difusiones (Salientes)',
            'EnviarMensaj': 'Enviar Mensaje de no Disponibilidad después de cada Mensaje',
            'RecibirConvers': 'Recibir Conversaciones Grupales',
            'PermiActuali': 'Permitir Actualizar los estados de los Mensajes',
            'PermiArchivos': 'Permitir Archivos',
            'BloqueVideos': 'Bloquear Vídeos',
            'MensajVideo': 'Mensaje para indicarle que no es posible recibir el Archivo de Vídeo',
            'PermitirAudios': 'Permitir Audios',
            'PermitirImágenes': 'Permitir Imágenes',

            'Done': 'Finalizado',
            'YouAreNowDone': 'Ya ha terminado.',



        },
        ListCanales: {
            'Vincular': 'Vincular',
            'Desvincular': 'Desvincular',
            'ActualizarDatos': 'Actualizar Datos',
            'Eliminar': 'Eliminar',
            "NroDeTeléfono": 'Número de Teléfono',
            'Bateria': 'Batería',
            'VerWhatsapp': 'Versión Whatsapp',
            'TotalDeMensaje': 'Total de Mensajes',
            'Online': 'En línea',
            'Status': 'Estado',
            'UltimaConexion': 'Última Conexión',

        },
        SelectCanales: {
            'Canal': 'Canal',
            'Canales': 'Canales',
            'Todos': '-- TODOS --',
        },

        Campos: {
            'Campo': 'Campo',
            'Tipo': 'Tipo',
            'Ubicacion': 'Ubicación',
            'DeseasEliminar': 'Deseas eliminar ',
            'PerderDatosCapturados': ' Se van a perder los Datos ya capturados!',
            'Nuevo_Campo': 'Nuevo Campo',
            'Editar_Campo': 'Editar Campo',
            'Otro': 'Otro',



            'Cadenas de Caracteres': 'Cadenas de Caracteres',
            'Correo': 'Correo',
            "Número": 'Número',
            'Teléfono': 'Teléfono',
            'Fecha': 'Fecha',
            'Nombre': 'Nombre',
            "Cédula/DNI": "Cédula/DNI",
            "Tarjeta de Credito": "Tarjeta de Credito",
            'Entrada': 'Entrada',
            'Texto': 'Texto',
            'Texto largo': 'Texto largo',
            'Numerico': 'Numérico',



            'Contactos': 'Contactos',
            'Chats': 'Chats',
            'Citas': 'Citas',
            'Cierre': 'Cierre',

            'Requerido': 'Es Requerido.',
            'ON': 'ENCENDIDO',
            'OFF': 'APAGADO',

            'ErrorCampo01:': 'Error Guardando Campo01: ',
            'ErrorCampo02:': 'Error Guardando Campo02: ',
            'Agregado': 'Campo Agregado',
            'ErrorAgreganCampo01': 'Error Agregando Campo01',
            'ErrorAgreganCampo02': 'Error Agregando Campo02'


        },
        Atajos: {
            'Abreviado': 'Abreviado',
            'texto': 'Texto',
            'Canales': 'Canales',
            'Ubicacion': 'Ubicación',
            'Seleccione': 'Seleccione...',
            'Duplicado': 'Item duplicado, debe usar otro atajo para abreviar',
            'DeseasEliminar': 'Deseas eliminar ',
            'Chat': 'Chat',
            'CierreDeCaso': 'Cierre de Caso',
            'NuevoAtajo': 'Nuevo Atajo',
            'EditarAtajo': 'Editar Atajo',
            'AtajoCreado': 'Atajo Creado',
            'GuardarCambios': 'Se logro guardas los cambios, pero no pudimos actualizar el sistema salga e ingrese de nuevo',
            'Error': 'ERROR CREANDO ATAJO:',
            'AtajoEditado': 'Atajo Editado',
            'ErrorEditando': 'ERROR EDITANDO:',



        },
        Etiquetas: {
            'Etiquetas': 'Etiquetas',
            'EditLabel': 'Editar Etiqueta',
            'AddLabel': 'Agregar Etiqueta',
            'AddNew': 'AGREGAR NUEVA ETIQUETA',

            'RemoveDueDate': 'Eliminar fecha de vencimiento',
            'AddChecklist': 'Agregar lista de verificación',
            'Unsubscribe': 'Cancelar suscripción',
            'Subscribe': 'Suscribirse',
            'RemoveCard': 'Eliminar tarjeta',
            'Title': 'Título',
            'DueDate': 'Fecha de vencimiento',
            'Description': 'Descripción',
            'Members': 'Miembros',
            'Attachments': 'Archivos adjuntos',
            'MakeCover': 'Hacer portada',
            'RemoveCover': 'Quitar cubierta',
            'RemoveAttachment': 'Eliminar adjunto',
            'Link': 'ENLACE',
            'AddAttachment': 'Agregar un archivo adjunto',
            'RemoveChecklist': 'Eliminar lista de verificación',
            'Comments': 'Comentarios',
            'Comment': 'Comentario',
            'Activity': 'Actividad',

            'AddCard': 'Agregar una tarjeta',

            'EtiquetaGuardada': 'Etiqueta Guardada',
            'ErrorEditando': 'Error Editando:',
            'EtiquetaAgregada': 'Etiqueta Agregada',
            'GuardarCambios': 'Se logro guardas los cambios, pero no pudimos actualizar el sistema salga e ingrese de nuevo',
            'ErrorGuardando': 'Error Guardando',
            'DeseasEliminar': 'Deseas eliminar ',

            'NuevaEtiqueta': 'Nueva Etiqueta',
            'EditarEtiqueta': 'Editar Etiqueta',
            'EliminarTarjeta': '¿Estás seguro de que quieres eliminar la tarjeta?',
            'EliminarLista': '¿Está seguro de que desea eliminar la lista y son todas las tarjetas?'
        },
        Horarios: {
            'Horarios': 'Horarios',
            'Excepciones': 'Excepciones',
            'DescripJorna': 'Descripción Jornada',
            'DiaInicio': 'Día Inicio',
            'DiaFin': 'Dia Fin',
            'HoraInicio': 'Hora Inicio',
            'HoraFin': 'Hora Fin',
            'Descripcion': 'Descripcción',
            'SeleccioneDia': 'Seleccione un día',
            'SeleccioneLaFecha': 'Seleccione La Fecha',
            'DescripcionEx': 'Ingrese una descripción de la excepción',

            'HorarioCreado': 'Horario Creado',
            'GuardarCambios': 'Se logró guardar los cambios en Base de Datos, pero no pudimos actualizar el sistema salga e ingrese de nuevo',
            'ErrorHorario': 'ERROR CREANDO HORARIO:',
            'HorarioActualizado': 'Horario Actualizado',
            'ErrorActualizando': 'ERROR ACTUALIZANDO HORARIO:',
            'Error': 'Error',

            'DeseasEliminar': 'Deseas eliminar ',
            'HorarioEliminado': 'Horario Eliminado',
            'HorariosElimidado': 'Horario Eliminado en base de datos pero no se pudo eliminar en memoria por favor salga y vuelva ingresar ERROR:',

            "Lunes": "Lunes",
            "Martes": "Martes",
            "Miercoles": "Miercoles",
            "Jueves": "Jueves",
            "Viernes": "Viernes",
            "Sábado": "Sábado",
            "Domingo": "Domingo",

            'Enero': 'Enero',
            'Febrero': 'Febrero',
            'Marzo': 'Marzo',
            'Abril': 'Abril',
            'Mayo': 'Mayo',
            'Junio': 'Junio',
            'Julio': 'Julio',
            'Agosto': 'Agosto',
            'Septiembre': 'Septiembre',
            'Octubre': 'Octubre',
            'Noviembre': 'Noviembre',
            'Diciembre': 'Diciembre',

            'HoraDeInicio': 'La hora de inico es igual a la hora final, colocar una hora correcta.',
            'HoraFinal': 'La hora de inico es mayor a la hora final, colocar una hora correcta.',


        },
        Agent: {
            'DatosPersonales': 'Datos Personales',
            'Nombre': 'Nombre',
            'Apellido': 'Apellido',
            'Email': 'Correo',
            'Contrasena': 'Contraseña',
            'Telefono': 'Teléfono',
            'Direccion': 'Dirección',
            'HorariosPermisos': 'Horarios / Permisos',
            'Canales': 'Canales',
            'Horarios': 'Horarios',
            'TipoDeUsuario': 'Tipo de Usuario',
            'RolDelUsuario': 'Rol del Usuario',
            'AnadirNuevoRol': 'Añadir nuevo rol',
            'Agentes': 'Agentes',
            'Ajustes': 'Ajustes',
            'Agente': 'Agente',
            'Clientes': 'Clientes',
            'Administrador': 'Administrador',
            'EmailDuplicado': 'Correo duplicado, debe usar otro Correo',
            'Master': 'Master',

            'CambioDeClave': 'Cambio de Clave',
            'ElPassword': 'La contraseña actual es incorrecto',
            'PasswordActual': 'Contraseña Real',
            'NuevoPassword': 'Nueva Contraseña',
            'PasswordRequired': 'Se requiere la contraseña',
            'ConfirmarPassword': 'Confirmar Contraseña',
            'PasswordCoinciden': 'La Contraseña no coincide',
            'data.Nombre': 'Agente',

            'AsignacionHorario': 'Asignación de horario Agente(s)',

            'EmailExistente': 'El Email ya existe, por favor agregar otro',
            'UserAgregado': 'Usuario Agregado',
            'UserEditado': 'Usuario Editado',

            'CambioClave': 'Se ha realizado con exito el cambio de clave',
            'ErrorClave': 'ERROR CAMBIANDO LA CLAVE',
            'Eliminar': 'Deseas eliminar a ',

            'DeseasColocar': 'Deseas colocar a ',
            'EstadoActualizado': 'Estado del agente actualizado',
            'Error': 'Error',
            'Seleccione': 'Seleccione...',
            'SeleccioneRol': 'Seleccione un rol...',
            'SeccionRequerida': 'Esta sección es requerida.',
            'TransferirConversaciones': '¿Puede Transferir conversaciones?',
            'Todos': 'Todos',

            'HorarioAgregado': 'Horario agregado a los agente(s)',
            'SeleccioneAgente': 'Por favor seleccione un agente que no tenga el horario',

            'BuscandoMensajes': 'Buscando Mensajes',
            'SinMensajes': ' se encuentra sin mensajes',
            'ChatAsignado': 'Chat Asignado',
            'SinMensajess': 'Sin Mensajes',
            'EnviarMensajes': 'Enviar mensajes',
            'MostrarAgentes': 'Mostrar Agentes'

        },
        tRoles: {
            'Rol': 'Rol',
            'domain': 'Dominio',
            'Descripcion': 'Descripción',
            'Permisos': 'Permisos',
            'Usuarios': 'Usuarios',

        },
        ContactoForm: {
            'Name': 'Nombre',
            'Lastname': 'Apellido',
            'CodeID': 'Código /ID',
            'PhoneNumber': 'Número de Teléfono',
            'Email': 'Correo',
            'Company': 'Compañia',
            'Birthday': 'Cumpleaños',
            'Address': 'Dirección',
            'Notes': 'Notas',
            'Contacto': 'Contacto',
            'Fecha': 'Fecha',
            'Telefono': 'Teléfono',
            'Code': 'Código',
            'Extra': 'Extra',
            'Categoria': 'CategorÍa',
            'Informacion': 'Información',
            'CamposAdic': 'Campo Adicional.',
            'JobTitle': 'Título profesional',
            'Remove': 'Eliminar',

            'ContactInfo2': 'Datos de contacto',
            'Mood': 'Estado de ánimo',

            'EliminarContacto': '¿Está seguro de que desea eliminar todos los contactos seleccionados?',
            'Eliminar': 'Deseas eliminar a ',
            'ListaNegra': 'Deseas enviar a lista Negra a',
            'ContactoAgregado': 'Contacto Agregado',
            'ErrorContacto': 'Error al crear el Contacto',

            'Nuevo Contacto': 'Nuevo Contacto',
            'Editar Contacto': 'Editar Contacto',
            'NuevoContacto': 'Nuevo Contacto',
            'EditarContacto': 'Editar Contacto',
            'ContactoEditado': 'Contacto Editado',
            'ContactoEliminado': 'Contacto Eliminado',
            'NoEliminarContacto': 'No se puedo eliminar el contacto',

            'EditarImagen': 'Editar imagen',
            'GuardadoExito': 'Guardado con exito'



        },
        Chat: {
            'Chat': 'Chat',
            'Select': '¡Seleccione un contacto para iniciar un chat!',
            'ContactInfo': 'Información de contacto',
            'YourBrowser': 'Su navegador no soporta la etiqueta de vídeo.',

            'Mood': 'Estado de ánimo',
            'Online': 'En Línea',
            'Away': 'Fuera',
            'DoNotDisturb': 'No molestar',
            'Offline': 'Desconectado',
            'Profile': 'Perfil',
            'Logout': 'Cerrar sesión',
            'Chats': 'Chats',
            'SearchChat': 'Buscar o iniciar un nuevo chat',
            'Contacts': 'Contactos',
            'NoResults': 'No hay resultados..',
            'Vacio': 'Vacio',
            'InfoContacto': 'Info. Contacto',
            'CerrarCaso': 'Cerrar Caso',
            'ConsultarSaldo': 'Consultar Saldo',

            'CierreDelCaso': 'Cierre del Caso ',
            'MotivoDelCaso': 'Motivo del Caso',
            'ConsultaDeSaldo': 'Consulta de Saldo',
            'Reclamo': 'Reclamo',
            'ReporteDePago': 'Reporte de Pago',
            'Otro': 'Otros',
            'Resumen': 'Resumen',
            'ClienteProspecto': 'Cliente Prospecto',
            'PromocionesEspeciales': 'Promociones Especiales',
            'ReporteProblemaAdministrativo': 'Reporte Problema Administrativo',
            'ReporteServicioTécnico': 'Reporte Servicio Técnico',
            'SolicitaInformacionDeServicio': 'Solicita Información de Servicio',

            'Consulta': 'Consulta',
            'Respuesta': 'Respuesta',
            'EnviarMensaje': 'Enviar mensaje',

            'Cargar': 'Cargar historial...',
            'Buscar': 'Buscar. . .',
            'AudioElement': 'Your browser does not support the audio element.',
            'Type': 'Escriba su mensaje',
            'SeleccionarEtiqueta': 'Seleccionar Etiqueta',
            'Emoji': 'Emoji seleccionado',

            'MensajesAtender': 'Mensajes por Atender:',
            'MensajesCargados': 'Mensajes Cargados 7 /',
            'Servidor': 'Servidor 7',

            'EnviarEncuestaRapida': 'Enviar Encuesta Rápida',
            'Titulo': 'Título',
            'Descripcion': 'Descripción',
            'MensajeAdic': 'Mensaje Adicional',
            'VistaPrevia': 'Vista Previa',
            'Seleccione': 'Seleccione...',

            'CasoExito': 'Caso cerrado con exito',
            'CambiandoEtiqueta': 'Se ha cambiado la etiqueta con exito',
            'ErrorEtiqueta': 'Error al cambiar la etiqueta',
            'HistorialDeContacto': 'No se ha encontrado historial de este contacto',
            'EnAtencion': 'En atención:',
            'ClientesEspera': 'Clientes en espera',


        },
        Galeria: {
            'Name': 'Nombre',
            'Type': 'Tipo',
            'Owner': 'Propietario',
            'Size': 'Tamaño',
            'Created': 'Creado',
            'Info': 'Información',
            'Asignar': 'Asignar a:',

            'EnviarImagen': 'Enviar archivo',
            'Descripcion': 'Descripción',
            'Galeria': 'Galería',

            'Ninguna': 'Ninguna',
            'Enviar': 'Enviar',
            'AdjuntarImagen': 'Adjuntar archivo',
            'Descargar': 'Descargar',

            'ImagenDisponible': 'Imagen Disponible:',
            'Titulo': 'Título',
            'Informacion': 'Información:',
            'Tamaño': 'Tamaño',
            'Asignado': 'Asignado',
            'Creado': 'Creado',
            'Modificado': 'Modificado',
            'MensajeAdicional': 'Mensaje Adicional',

            'AddList': 'Agregar una lista',
            'NombreDeImagen': 'Nombre de la imagen',

            'NuevaImagen': 'Agregar: Nueva Imagen',
            'IngreseImagen': 'Ingrese el nombre de la imagen...',
            'Seleccione': 'Seleccione...',
            'Cambiar': 'Cambiar',

        },
        Configuracion: {
            'Administración': 'Administración',

            'Settings': 'Configuración',
            'BoardColor': 'Color de la placa',
            'CardCoverImages': 'Imágenes de portada de tarjetas',
            'Subscribe': 'Suscribirse',
            'CopyBoard': 'Copiar tablero',
            'DeleteBoard': 'Eliminar tablero',
            'Background Color': 'Color de fondo',

            'Boards': 'TABLEROS',
            'ProyectosApp': 'Aplicación Proyectos',
            'AddNewBoard': 'Agregar tablero nuevo',

        },
        Bot: {
            'NuevaRespuestaDelBOT': 'Nueva Respuesta del BOT',
        },
        tPreguntas: {

            'question': '¿Cómo crear etiquetas?',
            'answer': '<p>Ingresa a Clientes >> Selecciona la empresa >> en la barra superior elige la opción de etiquetas >> le dará clic al icono azul (+) >> le abrirá una ventana llamada Nueva etiqueta como se muestra en la imagen ingresará el nombre que desee y el canal, luego proceda a AGREGAR.\n</p> <img src="/assets/img/examples/Etiqueta.PNG" alt="">',

            'question0': '¿Cómo agrego imágenes en la galería?',
            'answer0': '<p>Ingresas a Galería >> En la parte superior derecha encontraras un botón con el nombre de AGREGAR le das clic y te desplegará una ventana como se muestra en la imagen, en el campo de Asignar seleccionas la Empresa, luego le das clic en Agregar y te abrirá la ventana de tu computador y seleccionas una imagen, una vez seleccionada le da clic a guardar cambios.\n</p> <img src="/assets/img/examples/Galeria.jpg" alt="">',

            'question1': '¿Cómo agregar el Horario de un Agente?',
            'answer1': '<p>Ingresa a Clientes >> Selecciona la empresa, en la barra superior elige la opción de Horarios, le dará clic al icono con el símbolo (+) le abrirá una ventana igual como se muestra en la imagen, en Horarios >> Descripción de jornada colocas una breve descripción >> En Dia inicio deberás de seleccionar el día de la Semana en que el agente inicio su jornada laboral y en Hora inicio y fin deberás de ingresar el horario del Agente en horario militar ejemplo (08:00am /20:00pm).\n </p> <img src="/assets/img/examples/horarios.jpg" alt="">',

            'question2': '¿Cómo Agregar y Editar Agentes?',
            'answer2': '<p>Agentes >> En la parte superior derecha encontrará un botón (AGREGAR) Lo selecciona, le desplegará una ventana donde le aparecerá:</p> \n <p>1 – Datos personales: Nombre >> Apellido >> Correo >> Contraseña >> Teléfono >> Dirección. Complete todos los campos con los datos correctos y le da SIGUIENTE.</p> <img src="/assets/img/examples/agentes1.jpg" alt="">\n <p> En la segunda ventana igual rellene los campos:</p> \n <p> 2- Horarios / Permisos:  Clientes >> Canales >> Horarios >> Tipo de usuario >> Rol del usuario. Luego proceda a CREAR USUARIO.</p>\n <img src="/assets/img/examples/agentes2.jpg" alt=""> \n <p>Para Editar el Agente se pasa a los 3 puntos (...) que están al lado de cada agente, aparecerán varias opciones, haga clic en Editar luego se desplegará la misma ventana que se muestra en la imagen, pero con la información del Agente lo Editas, le das SIGUIENTE y Guardas los cambios.</p>',

            'question3': '¿Cómo crear Atajos o respuestas rápidas?',
            'answer3': ' <p>Ingresa a Clientes >> Selecciona la empresa, en la barra superior elige la opción de Atajos le dará clic al icono (+) le abrirá una ventana llamada Nuevo Atajo como se muestra en la imagen ingresará la Abreviación, el Texto, los Canales y la ubicación. Luego proceda a GUARDAR.\n<p> <img src="/assets/img/examples/Atajos.jpg" alt="">',

            'question4': '¿Cómo cambiar la clave de un Agente?',
            'answer4': ' <p> Seleccionas Agentes >> Al lado de cada Agente encontraras 3 puntos (...) le aparecerá la opción de Cambiar Clave, ingresa y le desplegará una ventana como se muestra en la imagen donde podrá realizar el cambio de clave del Agente seleccionado.\n</p> <img src="/assets/img/examples/CambioClave.jpg" alt="">',

            'question5': '¿Cómo Agregar y Editar Contacto?',
            'answer5': '<p> 1 - Para Agregar: Contactos >> En la parte superior derecha encontrará un botón (AGREGAR)al darle clic te desplegará una ventana llamada Nuevo Contacto, como se puede apreciar en la imagen, completas todos los campos y le das clic al botón de (AGREGAR) que se encuentra al final de los campos. \n</p> <img src="/assets/img/examples/Contactos1.jpg" alt=""> \n<p>2 – Editar: como se muestra en la imagen te diriges a los 3 puntos (...) que se encuentra al lado de cada contacto, le aparecerá la opción de Editar lo seleccionas, modificas el campo y le das clic al botón de editar.\n</p><img src="/assets/img/examples/Contactos2.jpg.png" alt="">',

            'question6': '¿Cómo Agregar y Editar información básica de un cliente?',
            'answer6': '<p> 1 - Para Agregar: Te diriges a Clientes >> En la parte superior derecha encontraras el botón de agregar le das clic y te desplegará una vista en la cual te pedirá la información básica del cliente que deseas agregar.\n</p><img src="/assets/img/examples/Cliente1.jpg" alt=""><p> 2 – Editar: Clientes >> como se muestra en la imagen te diriges a los 3 puntos (...) que se encuentra al lado de cada Empresa, le aparecerá la opción de Editar lo seleccionas, modificas el campo y le das clic al botón de GUARDAR.\n</p><img src="/assets/img/examples/Cliente2.jpg" alt="" >',

            'question7': '¿Cómo Agregar y Editar nuevo contacto del cliente?',
            'answer7': '<p> 1 -Para Agregar: Te diriges a Clientes >> Seleccionas la Empresa >> En la barra superior elige la opción de Contactos, le dará clic al icono con el símbolo (+) de color azul se abrirá una ventana igual como se muestra en la imagen, Nuevo Contactos llenas todos los campos y al final encontraras un botón de Agregar o Cancelar, al agregar se guardará tu contacto. \n</p> <img src="/assets/img/examples/CienteContacto1.jpg.png" alt=""> \n<p>2 -Editar: Como se muestra en la segunda imagen, debajo de Menú encontraras 3 rayitas que se encuentra al lado de cada contacto, le aparecerá la opción de Editar lo seleccionas, modificas el campo y le das clic al botón de Guardar.\n</p> <img src="/assets/img/examples/ClienteContacto2.jpg.png" alt="">',

            'question8': '¿Cómo Agregar y Editar el Rol de un Agente?',
            'answer8': '<p>1 - Para Agregar: Te diriges a Roles >> En la parte superior derecha encontraras el botón de Agregar le das clic y te desplegará una vista como se puede apreciar en la imagen con los campos de nombre, la descripción, los permisos y los usuarios. Luego solo le da clic en GUARDAR.\n</p> <img src="/assets/img/examples/Roles1.jpg " alt=""> \n<p>2 – Editar: como se muestra en la imagen te diriges a los 3 puntos (...) que se encuentra al lado de cada Rol del Agente, le aparecerá la opción de Editar lo seleccionas, modificas el campo y le das clic al botón de GUARDAR.\n</p> <img src="/assets/img/examples/Roles2.jpg " alt="">',

            'question9': '¿Cómo usar los Atajos del Chat?',
            'answer9': '<p> Ingresas a la opción de chat, seleccionas una conversación y en la barra de abajo como se muestra en la imagen colocas el símbolo (/) y luego te aparecerán algunos atajos, lo seleccionas y procedes a enviarlo.\n</p> <img src="/assets/img/examples/Atajoss.jpg" alt="">',

            'question10': '¿Cómo puedo enviar imágenes o archivos a un cliente?',
            'answer10': '<p> Ingresas a la opción de Chat, seleccionas una conversación y en la parte inferior derecha encontraras un icono de un clip lo seleccionas y te abrirá una venta como se muestra en la imagen, le das clic al botón de adjuntar archivo seleccionas el documento o imagen y luego le das al botón de enviar.\n</p> <img src="/assets/img/examples/AdjuntarImagen.jpg" alt="">',

            'question11': '¿Cómo Reasignar mensajes a otro Agente?',
            'answer11': '<p> Ingresas a la opción de chat, seleccionas una conversación y en la barra de abajo como se muestra en la imagen colocas el @ te aparecerán los nombres de los agentes seleccionas y luego envías.\n</p> <img src="/assets/img/examples/ReasignarMensajes.jpg" alt="">',

            'question12': '¿Cómo puedo verificar la información de un cliente?',
            'answer12': '<p> Ingresas a la opción de chat, seleccionas una conversación en la parte superior derecha encontraras 3 punticos le das clic y te desplegará un cuadro como aparece en la primera imagen, seleccionas Info.Contacto y te abrirá una ventana como se muestra en la segunda imagen.\n</p> <img src="/assets/img/examples/InfoCliente.jpg " alt=""> <img src="/assets/img/examples/InfoCliente2.jpg" alt="">',

            'question13': '¿Cómo consultar el Saldo de un cliente?',
            'answer13': '<p> Ingresas a la opción de Chat, seleccionas una conversación en la parte superior derecha encontraras 3 punticos le das clic y te desplegará un cuadro como aparece en la primera imagen, seleccionas Consultar Saldo o en el teclado (Ctrl+y) y te abrirá una ventana como se muestra en la segunda imagen en el campo de Consulta colocas la cedula o ID del cliente y en el campo de Respuesta su saldo y procede a enviar el mensaje.\n</p> <img src="/assets/img/examples/InfoCliente.jpg " alt=""> <img src="/assets/img/examples/ConsulaSaldo.jpg" alt="">',

            'question14': '¿Dónde puedo cerrar el caso de un cliente?',
            'answer14': '<p> Ingresas a la opción de Chat, seleccionas una conversación en la parte superior derecha encontraras 3 punticos le das clic y te desplegará un cuadro como aparece en la primera imagen, seleccionas Cerrar caso o en el teclado (Ctrl+b) y te abrirá una ventana como se muestra en la segunda imagen, te aparecerán 2 campos el Motivo del caso y el Resumen rellenas ambos y le da clic a Cerrar caso.\n</p> <img src="/assets/img/examples/InfoCliente.jpg " alt=""> <img src="/assets/img/examples/CierreCaso.jpg" alt="">',

            'question15': '¿Cómo acceder a la Encuesta rápida?',
            'answer15': '<p> Ingresas a la opción de Chat, seleccionas una conversación en la parte superior derecha encontraras 3 punticos le das clic y te desplegara un cuadro como aparece en la primera imagen, seleccionas Enviar encuesta rápida y te abrirá una ventana como se muestra en la segunda imagen, tienes varios campos Titulo, descripción, mensaje adicional luego encontraras 3 cuadritos donde colocaras (Ejem: Si o No) y  al rellenar los campos, abajo tienes una vista previa donde podrás ver como quedara tu encuesta antes de enviarla.\n</p> <img src="/assets/img/examples/InfoCliente.jpg " alt=""> <img src="/assets/img/examples/EncuestaRapida.jpg" alt="">',

            'question16': '¿Cómo etiquetar conversaciones?',
            'answer16': '<p> Ingresa al Chat >> Seleccione una conversación luego en la parte superior derecha del chat encontraras el icono de una etiqueta como se muestra en la imagen le da clic y selecciona el correspondiente.\n</p> <img src="assets/img/examples/EtiquetarConvers.jpg" alt="">',

            'question17': '¿Cómo agregar y eliminar imágenes en la galería de Clientes? ',
            'answer17': '<p>1 - Para Agregar: Te diriges a Clientes >> Seleccionas la Empresa >> En la barra superior elige la opción de galería, le das clic al botón azul con el signo de (+) que se encuentra en la parte superior izquierda. Te abrirá una ventana para que puedas agregar la imagen que se encuentra en tu computador como se muestra en la imagen, seleccionas la imagen y se te guardara.\n</p><img src="assets/img/examples/ImagenGaleria.jpg" alt=""> \n<p> 2-Eliminar: Le da clic a la imagen y en la parte inferior derecha encontraras un botón rojo ELIMINAR.  \n</p> <img src="/assets/img/examples/ImagenGaleria1.jpg" alt=""> ',
            //'answer17' : '<p>1 - Para Agregar: Te diriges a Clientes >> Seleccionas la Empresa >> En la barra superior elige la opción de galería, le das clic al botón azul con el signo de (+) que se encuentra en la parte superior izquierda. Te abrirá una ventana para que puedas agregar la imagen que se encuentra en tu computador como se muestra en la imagen, seleccionas la imagen y se te guardara.\n</p> <img src="/assets/img/examples/ImagenGaleria.jpg" alt=""> <p> 2-Eliminar: Le da clic a la imagen y en la parte inferior derecha encontraras un botón rojo ELIMINAR. \n</p> <img src"/assets/img/examples/ImagenGaleria1.jpg" alt="">',

            /*  'question18' : '¿Funcionalidad de Bloqueo?',
             'answer18' : '<p> En la parte superior derecha en el nombre de usuario le das clic como se muestra en la primera imagen seleccionas Bloqueo te abrirá una ventana como puedes visualizar en la segunda imagen, tienes un campo de Motivo donde puedes seleccionar la opción de Descanso, En reunión u Otro. También Puedes DESBLOQUEAR o en ¿No eres Tú? Puedes cambiar de usuario.\n</p> <img src="/assets/img/examples/Bloqueo.jpg" alt=""> <img src="/assets/img/examples/Bloqueo2.jpg" alt="">', */


            'FrequentlyAskedQuestion': 'Preguntas Frecuentes',
            'SearchInFAQs': 'Buscar en preguntas frecuentes...',

        },
        tNovedades: {

            'title': 'Prueba',
            'HowCanWehelp': '¿Cómo podemos ayudar?',
            'WelcomeToOurknowledgeBase': 'Bienvenido a nuestra base de conocimientos',
            'SeeallArticles': 'Ver todos los artículos',

            'Your Account': 'Personalizar',
            'Account limits': 'Apariencia del Sistema',
            'Contenido_Account_limits': '<p><b> Cambio de apariencia de la página. </b></p>\n<p> Nuestro sistema ahora cuenta con la opción de Personalizar la apariencia de la página con diferentes opciones de Temas y Tonalidades, así como personalizar la barra de navegación, el posicionamiento de la página la barra de herramienta y el pie de página eligiendo tu propio estilo y color de preferencia.\n</p><div class = "ImgKnow"><img src="/assets/img/examples/Personalizar.jpg" alt=""> </div> ',
            'Chat': 'Chat',
            'Chat1': 'Atajos del Chat',
            'Content_Chat1': '<p><b>Actualización de Chat</b> </p>\n<p> Ahora con la nueva versión de ThomasV9 en la sección de Chat podemos responder los mensajes de manera rápida y eficaz con los atajos o respuestas rápidas que podemos visualizar colocando el símbolo de la barra (/).\n</p> <div class = "ImgKnow"><img src="/assets/img/examples/Atajoss.jpg" alt=""> </div>',
            'Chat2': 'Cantidad de mensajes.',
            'Content_Chat2': '<p><b>Total de Conversaciones </b></p>\n<p> Otra de las nuevas mejoras del chat es la visualización de la cantidad de mensajes que están atendiendo los operadores y los clientes que están en espera.\n</p> <p>En atención >> Se muestra el total de chats que tiene en atención. \n</p> <p> Clientes en espera >> Muestra el total de clientes que esperan por ser atendidos. \n</p><div class = "ImgKnow"><img src="/assets/img/examples/TotalChat.jpg" alt="" ></div>',
            'Chat4': 'Reordenar el Chat',
            'Content_Chat4': '<p><b> Reorganización de los mensajes.</b> </p> \n <p> Ahora los agentes pueden reordenar las conversaciones mediante "Drag & Drop" (Arrastar y Soltar cualquier chat). \n </p><div class = "ImgKnow"><img src="/assets/img/examples/Reordenar chat.jpg" alt=""> </div>',

            'Chat3': 'Etiquetas',
            'Content_Chat3': '<p><b>Etiquetar una conversación. </b></p>\n<p> Con esta nueva actualización del chat puedes identificar conversaciones mediante etiquetas de Reporte de falla, Pagos o consulta de saldo, para agrupar los mensajes y poder obtener estadísticas de la cantidad de conversaciones o casos, gracias a la agrupación de estas conversaciones se obtienen eficientes reportes de gestión.\n</p><div class = "ImgKnow"><img src="/assets/img/examples/EtiquetarConvers.jpg" alt=""> </div>',
            'Inicio': 'Inicio',
            'Idioma': 'Idiomas',
            'content_Idioma': '<p><b>Cambio de Idiomas.</b></p>\n<p> Con esta nueva actualización del chat puedes identificar conversaciones mediante etiquetas de Reporte de falla, Pagos o consulta de saldo, para agrupar los mensajes y poder obtener estadísticas de la cantidad de conversaciones o casos, gracias a la agrupación de estas conversaciones se obtienen eficientes reportes de gestión.\n</p><div class = "ImgKnow"><img src="/assets/img/examples/Idioma.JPG" alt="" ></div>',
            'Bloqueo': 'Bloqueo',
            'content_Bloqueo': '<p><b>Bloqueo de sesión.</b></p>\n<p> También nuestro sistema cuenta con una opción de bloqueo de sesión donde podrás visualizar un contador que indica cuanto tiempo llevas desconectado y puedes indicar el motivo. \n</p><div class = "ImgKnow"><img src="/assets/img/examples/Bloqueo2.jpg" alt="" ></div>',
            'Galeria': 'Galería',
            'GaleriaDeImagen': 'Galería de imagenes',
            'content_Imagen': '<p><b>Sección de Galería.</b></p>\n<p>  Nuestro sistema cuenta con una sección de galería donde el departamento de diseño o mercadeo va a poder tener un administrador completo para la parte grafica que van a usar los agente a la hora de atender a los clientes de esta manera se hace más sencillo y eficaz tener todas las herramientas para una buena atención.\n</p> <p> En esta herramienta podremos agregar, eliminar, consultar imágenes y PDF que se le enviaran a nuestros clientes.\n</p><div class = "ImgKnow"><img src="/assets/img/examples/Galeria1.jpg" alt="" ></div>',

            'Agentess': 'Lista de agentes',
            'Agentes': 'Agentes',
            'content_Agentes': '<p><b> Listado de los agentes. </b></p>\n<p> Con la nueva actualización de Thomas se añadió esta nueva sección donde podrás visualizar una lista de todos los Agentes y los que pertenecen a cada Empresa, además tiene un menú con una serie de opciones para editar los datos del agente, cambiar su clave o eliminarlo de la lista.\n </p><div class = "ImgKnow"><img src="/assets/img/examples/Agentes.jpg" alt="" ></div>',
            'AgregarAgent': 'Nuevo agentes',
            'content_AgregarAgent': '<p><b> Agregar un nuevo Agente </b></p>\n<p> Desde el mismo sistema se podrán agregar los Agentes con los datos personales correspondiente, también podrás agregarle el horario laboral y los permisos o accesos colocado por el Administrador o Master. \n </p><div class = "ImgKnow"><img src="/assets/img/examples/agentes2.jpg" alt="" ></div>',
            'CambioClave': 'Cambio de Clave',
            'content_CambioClave': '<p><b>Cambio Clave de Agentes.</b></p>\n<p> De manera sencilla y rápida se podrá realizar el cambio de clave o contraseña del agente, colocando la clave actual, la nueva clave y la confirmación de la misma. \n </p> <div class = "ImgKnow"><img src="/assets/img/examples/AgenteClave.jpg" alt="" ></div>',
            'Roles': 'Roles',
            'Roles1': 'Roles o permisos',
            'content_Roles': '<p><b>Permisos de Agentes.</b></p>\n<p> Contamos con una sección llamada Roles, donde los administradores y masters tienen la posibilidad de Agregar, Editar y Eliminar los Roles, que determinan a que tienen permiso de ingresar y visualizar en el sistema. \n </p> <div class = "ImgKnow"><img src="/assets/img/examples/Roles2.jpg" alt="" ></div>',
            'Roles2': 'Opciones de Roles',
            'content_Roles2': '<p><b>Opciones para agregar un Rol.</b></p>\n<p> Para colocarles los permisos a un agente contamos con una serie de opciones como el Nombre si es un agente o administrador, una breve descripción del Rol, los permisos a las diferentes secciones que podrá visualizar en el sistema y por último tenemos una lista de usuarios u operadores al que se le asignará el Rol. \n </p> <div class = "ImgKnow"><img src="/assets/img/examples/Roles3.jpg" alt="" ></div>',
            'Contacto': 'Contactos',
            'Contacto1': 'Lista de contactos',
            'content_Contacto': '<p><b>Contacto de Clientes.</b></p>\n<p> En la plataforma contamos con una opción de Contacto donde podremos visualizar, consultar, agregar y editar Todos los números telefónicos de nuestros Clientes. \n </p> <div class = "ImgKnow"><img src="/assets/img/examples/Contactos1.jpg.png" alt="" ></div>',
            'Chat5': 'Total de Chat',
            'Content_Chat5': '<p><b>Total de conversaciones.</b></p>\n<p> Otra de las nuevas mejoras del chat es la visualización de la cantidad de mensajes que están atendiendo los operadores y los clientes que están en espera.\n </p> <p>En atención: 50 >> Se muestra el total de chats que tiene en atención. \n </p> <p>Clientes en espera >> Muestra el total de clientes que esperan por ser atendidos. \n </p><div class = "ImgKnow"><img src="/assets/img/examples/TotalChat.jpg" alt="" ></div>',
            'Chat6': 'Historial',
            'Content_Chat6': '<p><b>Historial de búsqueda.</b> </p> \n <p> Se puede buscar el historial de una conversación existente al escribir el número de teléfono y hacer click en buscar conversación. \n </p><div class = "ImgKnow"><img src="/assets/img/examples/Historial.jpg" alt="" ></div>',
            'Chat7': 'Archivos Multimedia del Chat',
            'Content_Chat7': '<p><b>Actualización del Chat.</b> </p> \n <p> Otras de las nuevas actualizaciones que se le realizó al chat es poder enviar y recibir Videos, documentos en PDF, emoji, stickers y hasta gif… Para que la interacción con nuestros clientes sea de mayor agrado.  \n </p><div class = "ImgKnow"><img src="/assets/img/examples/multimedia.JPG" alt="" ></div>',
            'Chat8': 'Encuestas del Chat',
            'Content_Chat8': '<p><b>Encuestas Rápidas.</b> </p> \n <p> Ahora se puede enviar encuestas con respuestas cerradas, arriba se edita y en la parte de abajo tienen la vista previa de cómo le llegara la encuesta al cliente (debe enviar mínimo 2 opciones y máximo 3). \n </p><div class = "ImgKnow"><img src="/assets/img/examples/EncuestaRapida.jpg" alt="" ></div>',
            'Chat9': 'Información del Contacto.',
            'Content_Chat9': '<p><b> Información del Contacto.</b></p> \n <p> También podremos visualizar la información personal del contacto que se encuentren almacenados en la base de datos, además se pueden agregar "Campos adicionales" (agregados desde el usuario). Estos campos cargaran en la pestaña de campos adicionales para que puedan almacenar información extra. \n </p><div class = "ImgKnow"><img src="/assets/img/examples/infoContacto.JPG" alt="" ></div>',
            'Tecnicos': 'Técnicos',
            'Tecnicoss': 'Registros de Técnicos',
            'content_Tecnicos': '<p> <b>Registros de técnicos</b> </p> \n <p>Actualmente contamos con el apartado de técnicos, los usuarios con acceso a esta sección pueden añadir nuevos técnicos correspondientes a sus empresas o compañías, fue creado principalmente para poder administrar cada uno de los técnicos, contratistas y vehículos por las empresas a las cuales se les tiene acceso, permitiendo agregar, consultar, y eliminar cada uno de esos registros. \n</p><div class = "ImgKnow"><img src="/assets/img/examples/Tecnicos.JPG" alt="" ></div>',
            'Reportess': 'Búsqueda avanzada',
            'Reportes': 'Reportes',
            'content_Reportes': '<p> <b>Reportes de búsqueda avanzada </b> </p> \n <p>Los usuarios con acceso a esta sección podrán realizar búsquedas específicas sobre el historial de conversaciones con los clientes de la empresa, a través de palabras claves, nombre del Cliente, teléfono, DNI, el canal por el cual se atendió al cliente, el tipo de mensaje, ya sea solo texto, imágenes, audios, videos, PDF, etc, podrán colocar etiquetas y un rango de fecha para realizar la búsqueda de toda esa información. \n </p> <div class = "ImgKnow"><img src="/assets/img/examples/Reportes.JPG" alt="" ></div>',
            'ResultadoBusqueda': 'Resultado de la búsqueda',
            'content_ResultadoBusqueda': '<p><b>Resultado de la búsqueda</b></p> \n <p>Tendrán distintas columnas dependiendo de la búsqueda que realice, este caso, se muestra en Móvil el numero telefónico del Cliente por el cual se recibió el mensaje, el tipo de contenido, el contenido del mensaje la fecha, También tendrán un filtro para agregar más columnas y podrán exportar a Excel el resultado final. \n </p> <div class = "ImgKnow"><img src="/assets/img/examples/Reporte1.JPG" alt="" ></div>',
        },
        tCustomize: {
            'ThemeOptions': 'Opciones de tema',
            'ColorThemes': 'Temas de color',
            'DefaultLight': 'Luz predeterminada',
            'YellowLight': 'Luz amarilla',
            'BlueGrayDark': 'Azul-Gris Oscuro',
            'PinkDark': 'Rosa oscuro',

            'LayoutStyles': 'Estilos de diseño',
            'VerticalLayout1': 'Disposición vertical n. °1',
            'VerticalLayout2': 'Disposición vertical n. °2',
            'VerticalLayout3': 'Disposición vertical n. °3',
            'HorizontalLayout1': 'Disposición Horizontal n. °1',

            'LayoutWidth': 'Ancho de diseño',
            'FullWidth': 'Ancho completo',
            'Boxed': 'En caja',

            'Navbar': 'Barra de navegación',
            'Hide': 'Esconder',
            'Folded': 'Doblado',
            'Position': 'Posición:',
            'Left': 'Izquierda',
            'Right': 'Derecha',
            'Variant': 'Variante:',
            'Style1': 'Estilo 1',
            'Style2': 'Estilo 2',
            'PrimaryBackground': 'Antecedentes primarios:',
            'SecondaryBackground': 'Antecedentes secundarios:',

            'Toolbar': 'Barra de herramientas',
            'Above': 'Sobre',
            'BelowStatic': 'Por debajo de estática',
            'BelowFixed': 'Abajo fijo',
            'UseCustomBackgroundColor': 'Usar color de fondo personalizado',
            'BackgroundColor': 'Color de fondo:',

            'Footer': 'Pie de página',
            'Color': 'Color:',

            'SidePanel': 'Panel lateral',
            'CustomScrollbars': 'Barras de desplazamiento personalizadas',
            'EnableCustomScrollbars': 'Habilitar barras de desplazamiento personalizadas',
        },
        Perfil: {
            'MyProfile': 'Mi Perfil',
            'Bloqueo': 'Bloqueo',
            'Logout': 'Cerrar sesión',

            'Follow': 'Sigue ',
            'SendMessage': 'Enviar mensaje',
        },
        Today: {
            'Today': 'Hoy',
            'Events': 'Eventos',
            'Notes': 'Notas',
            'QuickSettings': 'Ajustes rápidos',
            'Notifications': 'Notificaciones',
            'CloudSync': 'Sincronización en la nube',
            'RetroThrusters': 'Propulsores retro',
            'Version / Servidor': 'Versión / Servidor',
            'note.detail': 'Fecha de Corte:',
        },
        Login: {
            'Welcome': '¡Bienvenido a Thomas!',
            'Lorem': 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus ullamcorper nisl erat,vel convallis elit fermentum pellentesque. Sed mollis velit facilisis facilisis viverra.',
            'LoginTo': 'INICIAR SESIÓN',
            'Email': 'Correo',
            'EmailIsRequired': 'Correo electónico es requerido',
            'CorreoNoValido': 'El correo no es valido',
            'Password': 'Contraseña',
            'PasswordNoCorrecto': 'La contraseña no es correcta.',
            'NoReconoceClave': 'No se reconoce usuario o clave',
            'ExcedioMaximo': 'Excedió el máximo de intentos, Por favor intente más tarde.',
            'SuCuentaBloqueada': 'Su cuenta ha sido bloqueada por seguridad, comuníquese con su supervisor para mayor información.',
            'RememberMe': 'Recuérdame',
            'ForgotPassword': '¿Has olvidado tu contraseña?',
            'Login': 'Iniciar sesión',
            'LogInWithGoogle': 'Iniciar sesión con Google',
            'LogInWithFacebook': 'Iniciar sesión con Facebook',
            'HaveAnAccount': '¿No tienes una cuenta?',
            'CreateAccount': 'Crear una cuenta',
            'OR': 'O',
            'Accept': 'Aceptar',

            'Create': 'CREA UNA CUENTA',
            'NombreEmpresa': 'Nombre de la Empresa',
            'NameEmpresaRequired': 'Se requiere el nombre de la empresa',
            'NameRequired': 'Se requiere el nombre de la Empresa',
            'UbicacionEmpresa': 'Ubicación de la Empresa',
            'locationRequired': 'Se requiere la ubicación',
            'TelefonoContacto': 'Teléfono de Contacto',
            'PhoneRequired': 'Se requiere el número de teléfono',
            'Name': 'Nombre',
            'NameRequire': 'Se requiere el nombre',
            'PleaseAddress': ' Por favor, introduce una dirección de correo electrónico válida',
            'PasswordConfirm': 'Contraseña (Confirmar)',
            'PasswordRequired': 'Se requiere contraseña',
            'PasswordConfirmation': 'Se requiere confirmación de contraseña',
            'Creread': 'Acepto',
            'termsConditions': 'Términos y condiciones',
            'Createe': 'CREAR CUENTA',
            'AlreadyAccount': '¿Ya tienes una cuenta?',

            'RecoverPassword': 'RECUPERA TU CONTRASEÑA',
            'Send': 'ENVIAR',
            'Gologin': 'Volver a iniciar sesión',
            'ResetPassword': 'RESTABLECE TU CONTRASEÑA',
            'ResetMyPassword': 'RESTABLECER MI CONTRASEÑA',

            'YourSession': 'TU SESIÓN ESTÁ BLOQUEADA',
            'DueInactivity': 'Debido a la inactividad, su sesión está bloqueada. Ingrese su contraseña para continuar.',
            'Username': 'Nombre de usuario',
            'Unlock': 'DESBLOQUEAR',
            'AreYouNot': "¿No eres Tú?",
            'InvalidPassword': 'Contraseña no válida',
            'Motivo': 'Motivo',
            'lock': 'bloquear',
            'Descanso': "Descanso",
            'EnReunion': "En reunión",
            'Otro': "Otro",

            'ConfirmEmail': '¡Confirma tu dirección de correo electrónico!',
            'ConfirmationEmail': 'Se ha enviado un correo electrónico de confirmación a',
            'ConfirmMyEmail': 'Revise su bandeja de entrada y haga clic en el enlace "Confirmar mi correo electrónico" para confirmar su dirección de correo electrónico.',
            'GoBackToLoginPage': 'Volver a la página de inicio de sesión',


            'Post': 'CORRESPONDENCIA',
            'Posted': 'Publicado en tu biografía',
            'Shared': 'Compartí algo contigo',
            'SharedVideo': 'Compartió un video contigo',
            'SharedArticle': 'Compartió un artículo contigo',
            'Like': 'Me gusta',
            'Share': 'Comparte ',
            'Reply': 'Responder',
            'PostComment': 'Publicar comentario',
            'LatestActivity': 'Última actividad',
            'SeeAll': 'Ver todos',

            'ExtenderSesion': '¿Desea extender su sesión?',
            'SessionExtend': 'Extender Sesión',
            'CerrarSesion': 'Cerrar Sesión',
            'Cuenta': 'YA SU CUENTA HA SIDO VERIFICADA CON ÉXITO'
        },
        Event_Citas: {
            'Title': 'Título',
            'PrimaryColor': 'Color primario',
            'SecondaryColor': 'Color secundario',
            'AllDay': 'Todo el dia',
            'StartDate': 'Fecha de inicio',
            'StartTime': 'Hora de inicio',
            'EndDate': 'Fecha final',
            'EndTime': 'Hora de finalización',
            'Location': 'Localización',
            'Notes': 'Notas',

        },
        Calendar: {
            'Campañas': 'Campañas',
            'Eliminar': '¿Estás seguro de que quieres eliminar?',
            'ClickAdd': 'Haga clic para agregar / eliminar acceso directo',
            'Search': 'Buscar una aplicación o una página',
            'Shortcuts': '¡Todavía no hay atajos!'

        },
        Error: {
            'Sorry': 'Lo sentimos, pero no pudimos encontrar la página que está buscando',
            'GoBackToDashboard': 'Volver al panel de control',

            'WellInternet': 'Bueno, ¡Dañaste Internet!',
            'Justkidding': 'Es broma, parece que tenemos un problema interno, inténtalo de nuevo en un par de minutos',
            'ReportThisProblem': 'Informar de este problema',

            'Closed': '¡Cerrado por mantenimiento programado!',
            'SorryFor': 'Lamentamos las molestias.',
            'Please': 'Vuelva a comprobar más tarde.',
        },
        Factura: {
            'Invoice': 'Factura',
            'DueDate': 'FECHA DE VENCIMIENTO',
            'Service': 'SERVICIO',
            'Unit': 'UNIDAD',
            'UnitPrice': 'PRECIO UNITARIO',
            'Quantity': 'PRECIO UNITARIO',
            'Total': 'TOTAL',
            'Subtotal': 'TOTAL PARCIAL',
            'Tax': 'IMPUESTO',
            'Discount': 'DESCUENTO',
            'Please': "Por favor': 'Pague en un plazo de 15 días. Gracias por hacer negocios..",
            'InCondimentum ': 'In condimentum malesuada efficitur. Mauris volutpat placerat auctor. Ut ac congue dolor. Quisquescelerisque lacus sed feugiat fermentum. Cras aliquet facilisis pellentesque. Nunc hendreritquam at leo commodo, a suscipit tellus dapibus. Etiam at felis volutpat est mollis lacinia.Mauris placerat sem sit amet velit mollis, in porttitor ex finibus. Proin eu nibh id liberotincidunt lacinia et eget eros.',

            'Phone': 'Teléfono:',
            'Email': 'Correo Electrónico:',
            'Web': 'Web:',
            'Cliente': 'CLIENTE',
            'InvoiceDate': 'FECHA DE FACTURA',
            'TotalDue': 'TOTAL DEBIDO',


            'SimplePricing': 'Preços simples!',
            'Advanced': 'As ferramentas de suporte ao cliente mais avançadas com um preço simples e acessível. E você sempre pode tentar por 30 dias, grátis!',
            'Starter': 'Iniciar',
            'ForSmall': 'Para pequenas equipes',
            'Monthly': 'Mensal por usuário',
            'Unlimited': 'Projetos ilimitados',
            'UnlimitedPages': 'Páginas ilimitadas',
            'UnlimitedDiskSpace': 'Espaço em disco ilimitado',
            'FreeSupport': 'Suporte gratuito 24 horas por dia, 7 dias por semana',
            'GetStarted': 'COMECE DE GRAÇA',
            'DayFree': 'Teste gratuito de 7 dias para começar',
            'Pro': 'Pro',
            'ForLargerTeams': 'Para equipes maiores',
            'AdvancedReporting': 'Relatórios avançados',
            'Customizable': 'Interface personalizável',
            'Integration': 'Integração CRM',
            'DayFreee': 'Avaliação gratuita de 30 dias para começar',
            'Enterprise': 'Empresa',
            'ForBigTeams': 'Para grandes equipes',
            'ForFull': 'Para uma lista completa de recursos, ligue para nós',
            'DayyFree': 'Teste gratuito de 90 dias para começar',
            'Frequently': 'Perguntas frequentes',
            'HowDoesFree': 'Como funciona a avaliação gratuita?',
            'Lorem': 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur a diam nec augue tincidunta ccumsan. In dignissim laoreet ipsum eu interdum.',
            'CanCancel': 'Posso cancelar a qualquer momento?',
            'Aliquam': 'Aliquam erat volutpat. Etiam luctus massa ex, at tempus tellus blandit quis. Sed quis neque tellus.Donec maximus ipsum in malesuada hendrerit.',
            'WhatHappens': 'O que acontece depois que meu teste termina?',
            'CanDiscount': 'Posso ter um desconto?',

        },
        Citas: {
            'Citas': 'Citas',
            'Eliminar': '¿Estás seguro de que quieres eliminar?',
        },
        Inicio: {
            'FacturasMasivas': 'Facturas Masivas',
            'AnularFacturas': 'Anular Facturas',
            'ConsultarFactura': 'Consultar Factura',
            'NroFactura': 'Nro Factura',
            'TipoFactura': 'Tipo de Factura',
            'Estadistica': 'Estadística',
            'Cola': 'Cola',
            'Clientes': 'Clientes',
            'Cerrados': 'Cerrados',
            'Mensajes': 'Mensajes',
            'Nuevos': 'Nuevos',
            'Casos': 'Casos',
            'Atendidos': 'Atendidos:',
            'Ayerr': 'Ayer:',

            'Total': 'Total en cola:',
            'ThomasBOT': ' Thomas BOT:',
            'Reasignados': 'Reasignados:',
            'CasosCerrados': 'Casos Cerrados',

            'EnviosMasivos': 'Envios masivos',
            'SeleccionaCanal': 'Selecciona un canal',
            'SeleccionarArchivo': 'Seleccionar Archivo',
            'DetenerProceso': 'Detener proceso',
            'EliminarArchivo': 'Eliminar Archivo',
            'EnviarMensajes': 'Enviar',
            'CancelarMasivo': 'Cancelar',
            'ImagenMasivo': 'Añadir Imagen',
            'ImagenMasivoVer': 'Ver imagen',
        },
        Tecnicos: {
            'Contratistas': 'Contratistas',
            'SeleccionaContratistas': 'Selecciona los contratistas',
            'Nombre': 'Nombre',
            'Rif': 'Rif',
            'Telefono': 'Teléfono',
            'PersonaDecontacto': 'Persona de contacto',
            'Email': 'Correo electrónico',
            'Direccion': 'Dirección',
            'GuardarInformacion': 'Guardar Información',
            'AgregarTécnico': 'Agregar nuevo técnico',
            'AgregarContratista': '--- Agregar Contratista ---',
            'Apellido': 'Apellido',
            'Cédula': 'Cédula',
            'Telefono1': 'Teléfono 1',
            'Telefono2': 'Teléfono 2',
            'Birthday': 'Cumpleaños',
            'Vehiculos': 'Vehículos',
            'AgregarVehiculo': '--- Agregar Vehículo ---',
            'Contacto': 'Contacto',
            'Marca': 'Marca',
            'Modelo': 'Modelo',
            'Color': 'Color',
            'Placa': 'Placa',
            'Tipo': 'Tipo',
            'TipoDeVehiculo': 'Tipo de Vehículo',
            'PolizaDeSeguro': 'Póliza de Seguro',

            'DeseasEliminar': '¿Deseas Eliminar a ',
            'InformacionAgregada': 'Información agregada con éxito',
            'InformacionEditada': 'Información editada con éxito',

            'SeleccEmpresa': 'Debes seleccionar una empresa',
            'Cerrar': 'Cerrar',

            'ModificarContratista': 'Modificar contratista',
            'EliminarContratista': 'Eliminar contratista',
            'ModificarVehiculo': 'Modificar vehiculo',
            'EliminarVehiculo': 'Eliminar vehiculo',
            'Archivo': 'El archivo no debe pesar más de 4MB',
            'ErrorArchivo': 'Error al subir Archivo:',
            'ErrorGuardarlo': 'Error al Guardarlo',
            'ExpiraciónLicencia': 'Expiración de licencia'
        },
        Home: {
            'Beneficios': 'Beneficios',
            'Soluciones': 'Soluciones',
            'Nosotros': 'Nosotros',
            'Plantillas': 'Plantillas para WhatsApp',
            'Contacto': 'Contacto',
            'PlantilaConte': 'Cuando se trata de vender, la velocidad lo es todo. Acelere su velocidad de respuesta con nuestras plantillas integradas de WhatsApp. Puede enviar automáticamente mensajes basados en desencadenantes como, por ejemplo, llegar a una determinada etapa del proceso. ¿La mejor parte? Puede personalizar las plantillas con información del perfil del prospecto, para que ningún mensaje parezca robotizado.',
            'IniciarSesion': 'Iniciar sesión',
            'SoftwareChat': 'SOFTWARE DE CHAT MULTICANAL',
            'PotenciaNegocio': 'Transforme WhatsApp en un poderoso canal para obtener ingresos.',
            'OptimiceComunicacion': ' Vea las conversaciones con los clientes potenciales como un embudo de ventas visual, realice un seguimiento de las relaciones comerciales de una etapa a otra y agilice las comunicaciones con herramientas de automatización como los ',
            'SolicitarDemo': 'Solicitar Demo',
            'BeneficiosThomas': 'Beneficios de usar Thomas para atender todos tus Chats.',
            'MultiCanal': 'Personaliza tus mensajes',
            'multiplesAgentes': 'Podrás tener multiples agentes atendiendo conversaciones de',
            'Metricas': 'Chatbot para WhatsApp',
            'BotNegocio': 'Construir un chatbot para WhatsApp no tiene por qué ser difícil. Nuestro creador de BOT  sin código facilita la creación de un bot que pueda recopilar información de los prospectos, completar sus perfiles, iniciar acciones automáticas y mucho más.',
            'flujoDeConversaciones': 'Construya Whatsapp Bot para su negocio',
            'CalidadRespuesta': 'calidad de respuesta',
            'Agentes': 'por parte de tus agentes.',
            'Thomas': 'Campañas de mensajería a escala',
            'AppThomas': 'Envía un mensaje a sus clientes, a través de cualquiera de los canales conectados, de forma masiva, con sólo unos pocos clics. Ahora es más fácil enviar campañas de whatsapp a un grupo de usuarios seleccionado, o simplemente enviar un mensaje a todo el público.',
            'AutomatizaThomas': 'Automatiza con Thomas',
            'BrindaAtencion': 'Brinda atención 24/7 con nuestros',
            'ChatDiseñados': 'chat diseñados a la medida',
            'Necesidades': ' de tus necesidades.',
            'CasosDeUso': 'Casos de uso',
            'Ventas': 'Ventas',
            'AumentaConversiones': 'Aumenta las conversiones e impulsa las ventas mediante conversaciones en tiempo real.',
            'AtencionCliente': 'Atención al cliente y soporte',
            'BrindaSoporte': 'Brinda soporte oportuno a tus clientes a través de sus canales preferidos.',
            'LogisticaDitribucion': 'Logística y ditribución de pedidos',
            'GestionaPedidos': 'Gestiona la toma de pedidos con tus agentes o con nuestros chat diseñados a medida.',
            'HechoPor': 'Hecho por:',

            'Asesores': 'Sigue el desempeño de tus asesores ',
            'NuevoCliente': '¿Aún no sabe si alguien respondió a ese nuevo cliente?',
            'EnThomas': 'En Thomas, los gerentes no necesitan cuestionarse: puede crear y ver fácilmente tareas, abrir cualquier perfil de cliente para ver la historia completa y medir el rendimiento con nuestras herramientas de informes.',
            'Comunicacion': 'Es un centro de comunicación para todas sus conversaciones de negocios. Cada cliente potencial obtiene su propio perfil con todas las funciones, donde usted podrá comunicarse con ellos utilizando sus canales favoritos: llamada telefónica, SMS, correo electrónico o mensajería como WhatsApp.',
            'Equipo': 'Cuando se trata de usar WhatsApp en equipo, las cosas se pueden volver confusas rápidamente: números de teléfono separados, dispositivos diferentes... es un dolor de cabeza. Por eso Thomas permite que todos los miembros del equipo compartan una única cuenta de WhatsApp.',
            'Equipo2': 'Y no se preocupe por escarbar en conversaciones que no son suyas: en Thomas, sólo se le notifican los prospectos que se le asignan'
        },
        ValidationsForm: {
            "USERNAME_REQUIRED": "El usuario es requerido.",
            "USERNAME_MIN_LENGHT": "El campo usuario debe tener como m\u00ednimo 4 car\u00e1cteres.",
            "PASSWORD_REQUIRED": "La contraseña es requerida.",
            "PASSWORD_MIN_LENGHT": "El campo contraseña de tener como m\u00ednimo 4 car\u00e1cteres.",
            "REQUIRED": "Campo requerido",
            "LOGIN_INVALID": "Usuario y/o contrase\u00f1a incorrecta.",
            "MIN_CHARACTER_REQUIRED": "Longitud de datos menor a la mínima permitida.",
            "MAX_CHARACTER_ALLOWED": "Longitud de datos superior al máximo permitido",
            "CHARACTER_NOT_ALLOWED": "Dato Inv\u00e1lido.",

            "LOADING": "Cargando, por favor espere.",
            "SIGN_IN": "Ingresar"
        }

    }
};

