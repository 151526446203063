import { Injectable, AfterContentInit, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

import { FuseUtils } from '@fuse/utils';
import { LogService } from 'app/services/global/Log.service'

import { ChatFakeDb } from 'app/fake-db/chat';
import { Apollo } from 'apollo-angular';
import { reject } from 'lodash';
import { JwtHelperService } from '@auth0/angular-jwt';
import { ValidationsService } from 'app/services/global/validations.service';
import { FakeDbService } from 'app/fake-db/fake-db.service';
import { IdGenerateService } from 'app/services/global/id-generate.service';

@Injectable({
    providedIn: 'root'
})
export class ChatService implements AfterContentInit {

    public CargaChar: EventEmitter<boolean> = new EventEmitter();
    contacts: any[];
    chats: any[];
    user: any;
    onChatSelected: BehaviorSubject<any>;
    onContactSelected: BehaviorSubject<any>;
    onChatsUpdated: Subject<any>;
    onUserUpdated: Subject<any>;
    onLeftSidenavViewChanged: Subject<any>;
    onRightSidenavViewChanged: Subject<any>;
    onUpdateChatDialog: Subject<any>;

    private ChatActivo: any = {}

    Token = sessionStorage.getItem('DataLogin')
    await: any;
    YaTengoTodo: Boolean = false

    cambioChat: EventEmitter<any> = new EventEmitter();
    

    /**
     * Constructor
     *
     * @param {HttpClient} _httpClient
     */
    constructor(
        private _httpClient: HttpClient,
        private ChatFDb: ChatFakeDb,
        private _fakeDBService: FakeDbService,
        private apollo: Apollo,
        private _RegistrarLog: LogService,
        private _validationService: ValidationsService,
        private _idGenerateService: IdGenerateService
    ) {
        // Set the defaults
        this.onChatSelected = new BehaviorSubject(null);
        this.onContactSelected = new BehaviorSubject(null);
        this.onChatsUpdated = new Subject();
        this.onUserUpdated = new Subject();
        this.onLeftSidenavViewChanged = new Subject();
        this.onRightSidenavViewChanged = new Subject();
        this.onUpdateChatDialog = new Subject();


        if (ChatFakeDb.YacargueChats){
        console.log('YacargueChats if: ', ChatFakeDb.YacargueChats)
                this.cargarTodo()
                this.YaTengoTodo = true
        }else{
        console.log('YacargueChats else: ', ChatFakeDb.YacargueChats)
        setTimeout(() => {
            if (!this.YaTengoTodo) {
                this.cargarTodo()
                this.YaTengoTodo = true;
                // console.log("enviroment Ya tengo Todo")
            }
        }, 45000)
        }

    }

    reloadChat() {

        ChatFakeDb.chatsReady.subscribe(() => {
            this.cargarTodo()
                .then(() => {
                    this.YaTengoTodo = true
                })
        },
        (err)=>{console.error(err)})




        /*
            return new Promise((resolve, reject) => {
                setTimeout(resolve, 30, 'foo');
            }).then( () => {
                new Promise<void>( (resolve, reject) => {
                
                });
            }
            )
        */
    }
    //Cargar todo de ChatService
    async cargarTodo() {
        return Promise.all<[any, any, any]>([
            this.getContacts(),
            this.getChats(),
            this.getUser(),

        ]).then(
            ([contacts, chats, user]) => {
                this.contacts = contacts;
                this.chats = chats;
                console.log("CargarTodo: chats", this.chats)
                this.user = user;
                console.log("CargarTodo: User", this.user)
                this.CargaChar.emit(true)
            },
        ).catch((error)=>{console.error(error)});
    }

    ngAfterContentInit() {
    }

    chatChange(chat){

        this.cambioChat.emit(chat);

    }


    /**
     * Resolver
     *
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @returns {Observable<any> | Promise<any> | any}
     */

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
        const helper = new JwtHelperService();
        const decodedToken = helper.decodeToken(this.Token);
        //TODO cambiar esto cuando se tenga el orden de carga 
        this.reloadChat()
    }


    /**
     * Get chat
     *
     * @param contactId
     * @returns {Promise<any>}
     */
    getChat(contactId, lic): Promise<any> {
        if (!lic) {
            return
        }
        const chatItem = this.user.chatList.find((item) => {
            return item.contactId === contactId && item.lic == lic;
        });
        console.log("getChat()",chatItem);
        this.ChatActivo = { ...chatItem };


        // Create new chat, if it's not created yet.
        if (!chatItem) {
            this.createNewChat(contactId).then((newChats) => {
                this.getChat(contactId, lic);
            }).catch((error)=>{console.error("Error",error)});
            return;
        }

        return new Promise((resolve, reject) => {
            this._httpClient.get('api/chat-chats/' + chatItem.id) //consulta get 
                .subscribe((response: any) => {
                    const chat = response;
                    console.log("Response from api/chat-chats/",chat);
                    const chatContact = this.contacts.find((contact) => {
                        return contact.id == contactId;
                    });
                    const chatData = {
                        chatId: chat.id,
                        dialog: chat.dialog,
                        contact: chatContact
                    };
                    this.onChatSelected.next({ ...chatData });
                },
                (err)=>{console.error("Error",err)}, 
                reject);

        });


    }//Revisar esta funcion

    getChat2(contactId, lic): Promise<any> {
        try {
            return new Promise(async (resolve, reject) => {
                
                if (!lic) { reject('Lic es obligatoria') }
                const chatItem = await this.user.chatList.find((item) => {
                    return item.contactId === contactId && item.lic == lic
                });
                console.log("getChat2()",chatItem);

                // Create new chat, if it's not created yet.
                if (!chatItem) {
                    console.log("getChat2(), cree un chat nuevo")
                    await this.createNewChat(contactId).then((newChats) => {
                    console.log('createNewChat: ', contactId,newChats) 
                    });
                    resolve("NewChat");
                }

                // MANDAR UNREAD DEL CHAT A LA BASE DE DATOS SEGUN EL ID

                if(!chatItem) return


                await this._httpClient.get('api/chat-chats/' + chatItem.id) //consulta get con async await
                    .subscribe((response: any) => {
                       
                        console.log("Respondio",response)
                        const chat = response;
                        this.contacts=this.contacts.filter(Boolean)
                        const chatContact = this.contacts.find((contact) => {
                            return contact.id == contactId;
                        });

                        console.log("chatContact", chatContact)
                        const chatData = {
                            chatId: chat.id,
                            dialog: chat.dialog,
                            contact: chatContact
                        };
                        console.log("chatData", chatData);
                        console.log(this.onChatSelected)
                        this.onChatSelected.next(chatData);
                        resolve(chatData);
                    },
                    (err)=>{console.error("Error",err)},
                     reject);
            })

        } catch (error) {
            this._RegistrarLog.RegistrarLog(error);
            reject(error);
        }
    }

    /**
     * Create new chat
     *
     * @param contactId
     * @returns {Promise<any>}
     */
    createNewChat(contactId): Promise<any> {//Estoy trabajando en esta funcion para la creacion de un chat nuevo desde un contacto
         console.log('createNewChat: ', contactId);
         console.log(this.contacts);
         console.log(this.chats);
         let datosContacto: any = {};
        try {
            return new Promise(async (resolve, reject) => {
              let contact = this.contacts.find((item) => {
                return item.id == contactId;
              });
              if (contact == undefined) {
                console.log("no consegui un chat en el local.");
                //Tengo que buscar el contacto en el In-memory con el phone.
                await this._httpClient.get('api/contactos').subscribe((response:any)=>{
                    console.log("createNewChat()",response)
                    response.forEach((empresa:any) => {
                        console.log("empresa ",empresa)
                        empresa.data.forEach((contacto:any)=>{
                            
                            if (contacto.phone+'@c.us' == contactId){
                                console.log("Encontre el contacto");
                                contact = contacto;
                            }
                        });

                    });
                });
              }
              console.log("Contact en createNewChat: ", contact);

              let date = new Date();
              //!Aqui se genera el id como debe deser
              const chatId = FuseUtils.generateGUID();
              //Aqui pudiera llamar a
              let idConv = this._validationService.createIDbyLicPhone(
                "584120202020",
                contactId.replace("@c.us", "")
              );

              

              const chat = {
                id: idConv, //idConversacion estaba aqui, chatId,
                dialog: [],
              };
              this.chats.push(chat);
              console.log(this.chats);
              // console.log(contact)
              console.log(contactId);

              const chatListItem = {
                id: idConv,
                idEmpresa: contact.idEmpresa,
                lic: contact.idCanal[0], 
                contactId: contactId,
                name: contact.name,
                contenType: "0",
                unread: 0,
                lastMessage: "Nueva Conversacion",
                lastMessageTime: Date.parse(date.toISOString()).toString(),
                canal: "assets/icons/Whatsapp.png",
                etiqueta: "",
              };

              // Add new chat list item to the user's chat list
              console.log("createNewChat()",chatListItem);
              this.user.chatList.push(chatListItem);
              const contactoDataForChat = {
                avatar: "assets/images/avatars/profile.jpg",
                id: chatListItem.contactId,
                id2: chatListItem.lic,
                name: chatListItem.name,
              }
              this.contacts.push(contactoDataForChat); //Falta guardar esto en el In-memory la ruta  this._httpClient.get('api/chat-contacts')

              await this._httpClient.post("api/chat-contacts",contactoDataForChat).subscribe((response:any)=>{
                console.log("Nuevo contacto guardado para el chatv1", response);
              });
              console.log("User.ChatList",this.user.chatList);

              // Post the created chat
             await this._httpClient.post("api/chat-chats", { ...chat }).subscribe(
                async (response: any) => {
                  await this._httpClient
                    .post("api/chat-user/" + this.user.id, this.user)
                    .subscribe(async (newUserData: any) => {
                      await this.getUser().then((updatedUser) => {
                        
                        this.onUserUpdated.next({
                          updatedUser: updatedUser,
                          contacts: this.contacts,
                        });
                        resolve(updatedUser);
                      });
                    });
                },
                (err) => {
                  console.error("Error", err);
                },
                reject
              );
            });

        } catch (error) {
            this._RegistrarLog.RegistrarLog(error);
            reject(error);
        }

    }

    updateChats(chat: any) {
        this._httpClient.post('api/chat-chats', { ...chat })
            .subscribe((response: any) => {
                console.log("Actualizo");
                this.chats.push(response);
            }
            ,(err)=>{console.error("Error",err)});
    }

    /**
     * Select contact
     *
     * @param contact
     */
    selectContact(contact): void {
        /*         console.log('selectContact: ', contact) */
        this.onContactSelected.next(contact);
    }

    /**
     * Set user status
     *
     * @param status
     */
    setUserStatus(status): void {
        //console.log('setUserStatus: ', status)
        this.user.status = status;
    }

    /**
     * Update user data
     *
     * @param userData
     */
    updateUserData(userData): void {
        /*         console.log('updateUserData: ',userData) */
        this._httpClient.post('api/chat-user/' + this.user.id, userData)
            .subscribe((response: any) => {
                this.user = userData;
                /*                 console.log('asi quedo user: ', this.user) */
            }
            ,(err)=>{console.error("Error",err)});
    }

    /**
     * Update the chat dialog
     *
     * @param chatId
     * @param dialog
     * @returns {Promise<any>}
     */
    updateDialog(chatId, dialog): Promise<any> {
        //console.log('updateDialog: ', chatId, dialog)
        return new Promise((resolve, reject) => {

            const newData = {
                id: chatId,
                dialog: dialog
            };

            this._httpClient.post('api/chat-chats/' + chatId, newData)
                .subscribe(updatedChat => {
                    resolve(updatedChat);
                },
                (err)=>{console.error("Error",err)},
                reject);
        });
    }

    newContact(contact): Promise<any> {
        //console.log("getContacts()")
        return new Promise((resolve, reject) => {
            this._httpClient.post('api/chat-contacts/', contact)
                .subscribe((response: any) => {
                    /*                     console.log('this.contact: ',this.contacts)
                                        console.log('response: ',response) */
                    this.contacts.push(response)
                    resolve(response);
                },
                (err)=>{console.error("Error",err)},
                reject);
        });
    }


    /*
    *   TEST DE CONTACT
    *
    */

    getAll(): Promise<any> {
        // return new Promise((resolve, reject) => {
        //     Promise.all([
        //         this.getContacts(),
        //         this.getChats(),
        //         this.getUser()
        //     ]).then(
        //         ([contacts, chats, user]) => {
        //             this.contacts = contacts;
        //             this.chats = chats;
        //             this.user = user;
        //             console.log("getAll")
        //             resolve();
        //         },
        //         reject
        //     );
        // });
        //console.log("getContacts()")
        return new Promise((resolve, reject) => {
            try {
                Promise.all([this.getContacts(), this.getChats(), this.getUser()])
                    .then((result: any) => {
                        resolve(result);
                    })
                    .catch((err: any) => reject(err));
            } catch (error) {
                this._RegistrarLog.RegistrarLog(error);
                reject(error);
            }
        });
    }

    /**
     * Get contacts
     *
     * @returns {Promise<any>}
     */
    getContacts(): Promise<any> {
        //console.log("getContacts()")
        return new Promise((resolve, reject) => {
            try {
                this._httpClient.get('api/chat-contacts')
                    .subscribe((response: any) => {
                        console.log("getContacts()",response);
                        resolve(response);
                    }, reject);
            } catch (error) {
                this._RegistrarLog.RegistrarLog(error);
                reject(error)
            }
        });
    }

    /**
     * Get chats
     *
     * @returns {Promise<any>}
     */
    getChats(): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.get('api/chat-chats')
                .subscribe((response: any) => {
                    resolve(response);
                },
                (err)=>{console.error("Error",err)},
                reject);
        });
    }

     //================================================== GetCargarChatsPaginados ==================================================================

    getChatsPaginados() {
        //Estoy usando una clase llamada ChatFakeDb
        ChatFakeDb.CargarChatsPaginados()
        
    }

    getChatsPaginados2(page:number){
        console.log("getChatsPaginados2");
        console.log(page);
        ChatFakeDb.uploadPaginatedChats(page);
    }

    /**
     * Get user
     *
     * @returns {Promise<any>}
     */
    getUser(): Promise<any> {
        //console.log("getUser()")
        return new Promise((resolve, reject) => {
            this._httpClient.get('api/chat-user')
                .subscribe((response: any) => {
                    resolve(response[0]);
                }, 
                (err)=>{console.error("Error",err)},
                reject);
        });
    }

    GetChatByPhone(phone: String) {
        return new Promise((resolve, reject) => {
            try {

                this.ChatFDb.ChatsByPhones2(this.apollo, this.Token, phone).then((result: any) => {
                    resolve(result)
                })

            } catch (error) {
                this._RegistrarLog.RegistrarLog(error);
                reject(error);
                console.error('Error In GetChatByPhone ' + error)
            }
        });
    }

    GetChatHistoryByPhone(phone: String, page: number, limit: number) {
        return new Promise((resolve, reject) => {
            try {

                this.ChatFDb.ChatHistoryByPhones(this.apollo, this.Token, phone, page, limit).then((Result: any) => {
                    resolve(Result)
                })

            } catch (error) {
                console.error('Error In GetChatHistoryByPhone ' + error)
                this._RegistrarLog.RegistrarLog(error);
                reject(error)
            }
        });
    }

    GetChatByLicandPhone(phone: String,lic:String,) {
        console.log("GetChatByLicandPhone: ",phone,lic)
        return new Promise((resolve, reject) => {
            try {
                this.ChatFDb.chatbyLicAndPhone(this.apollo, this.Token, phone, lic).then((result: any) => {
                    resolve(result)
                })

            } catch (error) {
                this._RegistrarLog.RegistrarLog(error);
                reject(error);
                console.error('Error In GetChatByPhone ' + error)
            }
        });
    }

    updateChatActivo(data: any) {
        this.ChatActivo = { ...data };

        /*         console.log(this.chats)
                console.log(this.getChats()) */
        /*         console.log("update chat activo: ", this.ChatActivo) */
        /*
        //Ejemplo de la estructura
        canal: "assets/icons/Whatsapp.png"
        contactId: "51970868294@c.us"
        id: "801fcc3a-7e9b-3932-06fb-a9e3a25bdb18"
        idEmpresa: "prueba123456"
        lastMessage: "Buenas tardes, mi pago ya fue . . ."
        lastMessageTime: "1625163506072"
        lic: "51957052872@c.us"
        name: "🌼A D R I A N A"
        unread: 0
        */
    }

    updateEtiqueta(etiqueta: any) {
        //this.ChatActivo.etiqueta=etiqueta
        //console.log(this.user)
        let finded = this.user.chatList.find((chat) => chat.id == this.ChatActivo.id)
        //console.log(finded)
        if(finded) {
            finded.etiqueta = etiqueta
         }
        //console.log(this.user)
        this.updateUserData(this.user)
    }

    getChatActivo() {        
        return this.ChatActivo;
    }

    getChatEtiquetas() {
        try {
            if (this.ChatActivo.etiqueta != undefined) 
                if (typeof this.ChatActivo.etiqueta == "string") 
                    if (this.ChatActivo.etiqueta != "")  return this.ChatActivo.etiqueta.substring(0, 25) + "...";
                    else return ""            
                else return ""            
            else return ""   
        } catch (error) {
            return ""  
        }
             
    }

    GetTotalCola(Token:any) {
        return new Promise((resolve, reject) => {
            try {
                this.ChatFDb.MSJInCola(this.apollo, Token).then((res:any)=>{
                    //console.log("res in promise",res)
                    resolve(res)
                })
            } catch (error) {
                console.error(error)
            }
        })
    }
   
   //Para limitar el largo de los nombres en el chat 
  CheckName(name:any){
    
    // console.log(newName);
    try {
        let newName:any = name
        if (name.length>20) {
            /*         console.log("si") */
                    if (name.includes(" ")){
            /*         console.log("Si tiene") */
                    newName = name.split(" ")
            /*         console.log(newName) */
                   newName= `${newName[0]} ${newName[1]}`
                    }else{
            /*         console.log("No tiene") */
                    newName = name.substring(0,20)
                    }
                } else {
                    newName = name
                }
            /*     console.log(newName) */
                    return newName
        
    } catch (error) {
        console.error("Error",error);
    }
    
  }


  newChatIncoming(chat: any) {
    const chatItem = this.user.chatList.find((item) => {
        return item.contactId === chat.phone;
    });
    if( chatItem === undefined ) {
        this.newContact(chat);
        this.createNewChat(chat);
        return;
    }
   
    const chatsById = this.chats.find((chat_) => {
        return chat_.id === chat.idConversacion
    });

    if ( chatsById !== undefined ) {
        
        // verificar si es necesarios su uso mas adelante 
        /*
            let existMessage = this.validateIfMessageExist(chat);
            console.log(existMessage);
            if(existMessage) return;
        */
        chatsById.dialog.push({
            ...chat,
            idmessage: this._idGenerateService.uniqueID(),
            TimeRead: new Date(chat.TimeRead).getTime().toString(),
            time: new Date(chat.TimeRead).getTime().toString(),
            timestamp: new Date().toISOString(),
            message: chat.content,
            who: chat.phone
        });
        this.updateDialog(chatsById.id, chatsById.dialog).then((res) => {
            this.onUpdateChatDialog.next(chatsById.dialog);
        }).catch((error)=>{console.error("Error",error)})
    }
  }

}

