import { Injectable } from '@angular/core';
import Swal from 'sweetalert2';
import { ExportExcelService } from './ExportExcel.service';

@Injectable({
  providedIn: 'root'
})
export class SweetAlertService {

  constructor( private exportExcel: ExportExcelService ) { }

  downloadExcel(data: any[], columns: string[], columnsNames: string[] ,typeData: string[]) {
    Swal.fire({
      title: 'Escribe un nombre al documento de excel',
      input: 'text',
      inputAttributes: {
        autocapitalize: 'off'
      },
      showCancelButton: true,
      confirmButtonText: 'Guardar',
      cancelButtonText: 'No guardar',
      confirmButtonColor: '#039be5',
      cancelButtonColor: '#3c4252',
      width: '46rem',
      heightAuto: false
    }).then((result) => {
      if( result.isConfirmed ) {
        if( result.value.length > 0 ) {
          this.exportExcel.exportDataToExcel(data, columns,`docuement-${Math.random() * 100}`, columnsNames, typeData, result.value);
        } else {
          this.exportExcel.exportDataToExcel(data, columns, `reporte-${Math.random() * 100}`, columnsNames ,typeData);
        }
      }
    }).catch((Error) => { console.error(Error) })
  }

  showLoading() {
    Swal.fire({
      title: 'Cargando, por favor espere...',
      width: '46rem',
      heightAuto: false,
      didOpen: () => {
        Swal.showLoading();
      }
    }) 
  }

  closeAlert() {
    Swal.close();
  }

  ShowError(Title: string, Text?: string, Footer?: any) {
    Swal.fire({
      icon: 'error',
      title: Title,
      text: Text,
      footer: Footer
    })
  }
}
