import { Injectable, OnInit } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import {GetTableV2Service} from 'app/services/global/getTableV2.service';
import { ShowDataService } from 'app/services/global/show-data.service';
import { ConsultaContactosByCliente, ConsultaContactosByPhone, ContactsFakeDb } from '../../../fake-db/contacts';
import { Apollo } from 'apollo-angular';
import { LoginSessionService } from 'app/services/global/LoginSession.service';
import { HttpClient } from '@angular/common/http';
import { FakeDbService } from 'app/fake-db/fake-db.service';
import { Subject } from 'rxjs';

  // import { GetTableService } from '../e-commerce/getTable.service';
@Injectable({
  providedIn: "root",
})
export class ContactosService implements Resolve<any>, OnInit {
  contactos: any = [];
  TokenLogin: string;
  cargarMasContactosSeleccionado : Subject<any>;

  constructor(
    private _GetTableV2Service: GetTableV2Service,
    private showData: ShowDataService,
    private apollo: Apollo,
    private _LoginSessionService: LoginSessionService,
    private _http: HttpClient,
    private _fakeDbService: FakeDbService
  ) {
    this.TokenLogin = this._LoginSessionService.GetToken();
    this.cargarMasContactosSeleccionado = new Subject();
  }

  ngOnInit() {
    
    ContactsFakeDb.Counting.subscribe(
      (res) => {
        console.log("llegue");
        this.showData.showData.emit(true);
      },
      (error) => {
        console.error("Error", error);
      }
    );
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {}

  reloadContactos(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this._GetTableV2Service.TableURL = "contactos";
      this._GetTableV2Service
        .getData()
        .then((DataIn) => {
          this.contactos = DataIn;
          resolve(this.contactos);
        })
        .catch((err) => reject(err));
    });
  }

  getContactos(): Promise<any> {
    return new Promise<any>(async (resolve, reject) => {
      if (this.contactos.length < 1) {
        await this.reloadContactos()
          .then((res) => {
            resolve(res);
          })
          .catch((err) => reject(err));
      } else {
        resolve(this.contactos);
      }
    }).catch((error) => {
      console.error("Error", error);
    });
  }

  //================================================== Query GraphQL ==============================================================
  private contadorPaginasContactos: number = 1;
  getContactsByPage() {    
      return new Promise((resolve,reject)=>{
        this.apollo
        .watchQuery({
          query: ConsultaContactosByCliente,
          variables: {
            token: this.TokenLogin,
            limit: 100,
            page: this.contadorPaginasContactos,
          },
        })
        .valueChanges.subscribe((response: any)=>{
          console.log("Pagina Actual ", this.contadorPaginasContactos);
          this.contadorPaginasContactos +=1;
           resolve(response);
        }, (error:any)=>{
          reject(error);
        });
      }).catch(error=>console.log("getContactsByPage()",error)); 
  }

  getContactByPhoneFromAPI(phone: string){
    //Nota: se debe concatenar la final del numero el '@c.us'
    return new Promise((resolve,reject)=>{
      this.apollo.watchQuery({
        query:ConsultaContactosByPhone,
        variables:{
          Phone: phone
        }
      }).valueChanges.subscribe((contactoFromAPI: any)=>{
        if (contactoFromAPI){
          resolve(contactoFromAPI.data.ContactosByPhone[0]);
        } else{
          console.log(`El usuario con numero ${phone} no se encuentra registrado!!!`);
        }
       
      }, (error:any)=>{
        reject(error);
      })
    }).catch((error: any)=>{console.log("getContactByPhoneFromAPI()",error)});
  }

  getContactsFromInMemory(licencia:string){
    return new Promise(async (resolve,reject)=>{
      await this._fakeDbService.getContactsByLicencesFromInMemory(licencia,this._http).then((allContactsFromLic)=>{
        resolve(allContactsFromLic);
      }).catch((error:any)=>{console.log("getContactsFromInMemory", error)});
    }) 
  }

  //Esta funcion la utilizo para guardar en el in-memory un nuevo contacto
   updatesaveNewContactsFromInMemory(licencia:string, data){
    this._fakeDbService.saveNewContactsFromInMemory(licencia,data.data,this._http);
  }

  //Esta funcion la utilizo para actualizar un contacto ya existente en el inmemory
  updateContactFromInMemory(contactPhone: string, licencia:string, updatedContact: Object, _HttpClient: HttpClient){
    contactPhone = contactPhone.slice(0,contactPhone.length - 5);
    // console.log(contactPhone)
    // console.log(licencia)
    // console.log(updatedContact)

    this._fakeDbService.updateContactFromInMemory(contactPhone,licencia,updatedContact,_HttpClient);
  }

  

  //==============================================================================================================================================
}
