import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FormatearTelefonoService {

  constructor() { }

  formatPhone(phone: string) {
    if (phone[0] == '+'){
     phone = phone.slice(1,phone.length);
    }
    
    const initPhone = phone.substring(0, 3);
       
    if (phone.charAt(0) === '0' && initPhone != '000') {
      const newphone = `58${phone.substring(1, phone.length)}@c.us`;
      return newphone;
    } else {
      if (
        initPhone === '414' ||
        initPhone === '424' ||
        initPhone === '412' ||
        initPhone === '416' ||
        initPhone === '426'
      ) {
        return `58${phone}@c.us`;
      } else if (initPhone != '000') {
        return `${phone}@c.us`;
      }
    }
    return;
  }
}
