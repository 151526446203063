import { Injectable, Output, EventEmitter } from '@angular/core';
import { DatePipe } from '@angular/common';
import { TakeDateService } from './take-date.service';
import * as XLSX from 'xlsx';

const EXCEL_EXTENSION = '.xlsx';
type AOA = any[][];

@Injectable({
  providedIn: 'root'
})
export class ExportExcelService {

  @Output() excelToJson_: EventEmitter<any> = new EventEmitter<any>();
  @Output() invoiceExcelToJson_: EventEmitter<any> = new EventEmitter<any>();

  public data: any;
  public daysOfTheWeek = ['Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado', 'Domingo'];

  constructor(private datePipe: DatePipe,
    private takeDateService: TakeDateService) { }

  takeDate(date: Date): string {
    return `${this.daysOfTheWeek[date.getDay() - 1]}, ${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`;
  }

  exportExcel(data: any, workSheetColumnNames: any, workSheetName: any, workSheetColumnNamesToDisplay: string[], fileName: string) {
    let columnsNames: any;
    if (workSheetColumnNamesToDisplay.length > 1) {
      columnsNames = workSheetColumnNamesToDisplay;
    } else {
      columnsNames = workSheetColumnNames;
    }
    const workBook: XLSX.WorkBook = XLSX.utils.book_new();
    const workSheetData = [
      columnsNames,
      ...data
    ];
    const workSheet: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(workSheetData);
    const append = XLSX.utils.book_append_sheet(workBook, workSheet, workSheetName);
    const write = XLSX.writeFile(workBook, `${fileName}${EXCEL_EXTENSION}`, { bookType: 'xlsx' });
    // return writeToBase64;

  }

  exportDataToExcel(dataToConvert: any[], workSheetColumnNames: any[], workSheetName: any, workSheetColumnNamesToDisplay: string[], typeData: string[], fileName: string = 'data') {
    let change = dataToConvert;
    const data = change.map((data: any) => {
      let arr = [];
      for (let index = 0; index < workSheetColumnNames.length; index++) {
        if (data[workSheetColumnNames[index]] === null) {
          data[workSheetColumnNames[index]] = false;
        } //else if ( typeData[index] === 'date' ) {
        //data[workSheetColumnNames[index]] = this.takeDateService.init(data[workSheetColumnNames[index]])
        // data[workSheetColumnNames[index]] = this.datePipe.transform(data[workSheetColumnNames[index]], 'dd/MM/yyyy, h:mm a');
        //let date = new Date(parseInt(data[workSheetColumnNames[index]]));
        //data[workSheetColumnNames[index]] = this.takeDate(date);
        //}
        arr.push(data[workSheetColumnNames[index]]);
      }

      return arr;
    });
    this.exportExcel(data, workSheetColumnNames, workSheetName, workSheetColumnNamesToDisplay, fileName);
    // let base64 = this.exportExcel(data, workSheetColumnNames, workSheetName, fileName);
    // return base64;
  }

  excelToJson(event: any) {
    const target: DataTransfer = <DataTransfer>(event.target);
    if (target.files.length !== 1) throw new Error('Cannot use multiple files');
    const reader: FileReader = new FileReader();
    reader.onload = (e: any) => {
      const bstr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' });
      const wsname: string = wb.SheetNames[0];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];
      this.data = XLSX.utils.sheet_to_json(ws, { header: ['phone', 'message'], range: 1 });
      this.excelToJson_.emit(this.data);
    };
    reader.readAsBinaryString(target.files[0]);
  }

  invoiceExcelToJson(event: any) {
    return new Promise(async (resolve: any, reject: any) => {
      try {

        const target: DataTransfer = <DataTransfer>(event.target);
        let DataFinal: any = []
        let JsonFormat: any = {}
        let NewKeyJson: any
        if (target.files.length !== 1) throw new Error('Cannot use multiple files');
        const reader: FileReader = new FileReader();
        //Leo el excel
        reader.onload = (e: any) => {
          const bstr: string = e.target.result;
          const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary', cellDates: true });
          const wsname: string = wb.SheetNames[0];
          const ws: XLSX.WorkSheet = wb.Sheets[wsname];
          const datos = <AOA>(XLSX.utils.sheet_to_json(ws, { header: 0 }))

          //Recorro la data
          for (let index = 0; index < datos.length; index++) {
            JsonFormat = {}

            try {

              for (var KeyJson in datos[index]) {
                NewKeyJson = KeyJson.trim()
                //Les doy formato a las fechas
                if (NewKeyJson === 'FECHA' || NewKeyJson === 'FECHATASA' || NewKeyJson === 'FECHAFACTURAFECTADA') {
                  if (typeof datos[index][KeyJson] == "object") {
                    JsonFormat[NewKeyJson] = datos[index][KeyJson].toISOString();
                  } else if (typeof datos[index][KeyJson] == "string") {
                    JsonFormat[NewKeyJson] = this.ConvertStringToFecha(datos[index][KeyJson]).toISOString();
                  }
                } else if (NewKeyJson === 'TELEFONO') {
                  JsonFormat[NewKeyJson] = datos[index][KeyJson].toString()
                } else if (NewKeyJson === 'ART') {
                  JsonFormat[NewKeyJson] = datos[index][KeyJson].toString()
                } else if (NewKeyJson == 'BASE' || NewKeyJson == 'IVA' || NewKeyJson == 'NETO' || NewKeyJson == 'IGTF' || NewKeyJson == 'BASEUSD' || NewKeyJson == 'IVAUSD' || NewKeyJson == 'NETOUSD' || NewKeyJson == 'IGTFUSD' || NewKeyJson == 'TASABCV' || NewKeyJson == 'MONTOFACTURAFECTADA' || NewKeyJson == 'BASEIGTF' || NewKeyJson == 'BASEIGTFUSD' || NewKeyJson == 'EXENTO' || NewKeyJson == 'EXENTOUSD') {
                  //les doy formato a los montos
                  if (typeof datos[index][KeyJson] === "number") {
                    const formatter = new Intl.NumberFormat('de-DE', {
                      style: 'currency',
                      minimumFractionDigits: 2,
                      currency: "EUR"
                    })
                    const NewValor = formatter.format(datos[index][KeyJson]).replace(" €", "")
                    JsonFormat[NewKeyJson] = NewValor
                  } else { JsonFormat[NewKeyJson] = datos[index][KeyJson] }

                } else {
                  //aqui no hace falra dar formato a los campos
                  JsonFormat[NewKeyJson] = datos[index][KeyJson]
                }
              }
              DataFinal.push(JsonFormat)
              if (index === datos.length - 1) {
                //si estoy en la ultima posicion del arreglo envio la data
                this.InvoiceWithMultiItm(DataFinal).then(Res => {
                  resolve(Res)
                })
              }

            } catch (error) {
              console.error(error)
            }
          }

        };
        reader.readAsBinaryString(target.files[0]);

      } catch (error) {
        reject(error)
      }
    })
  }


  InvoiceWithMultiItm(DatosInvoice: any) {
    return new Promise(async (resolve: any, reject: any) => {
      try {

        let DataRetorno: any = []
        let FacturasItem: any = []
        let ClientesItem: any = []
        let DetalleFactura: any = []
        let TotalBase = 0, TotalIva = 0, TotalBaseUSD = 0, TotalIvaUSD = 0
        let ID_Json: number = 1

        if (DatosInvoice && DatosInvoice.length > 0) {

          for (let index = 0; index < DatosInvoice.length; index++) {
            //busco si ya procese la data de ese cliente
            if (DatosInvoice[index] && DatosInvoice[index].TIPOFACTURA && (DatosInvoice[index].CLIENTE && DatosInvoice[index].CODIGO) || (DatosInvoice[index].FACTURA && DatosInvoice[index].MOTIVO)) {
              FacturasItem = []
              DetalleFactura = []
              TotalBase = 0
              TotalIva = 0
              TotalBaseUSD = 0
              TotalIvaUSD = 0
              const No_lo_Tengo = !ClientesItem.find((FC: any) => FC.Cliente === DatosInvoice[index].CLIENTE && FC.item === DatosInvoice[index].ITEM)
              //const ID_Json: any = Math.random().toString(16).slice(3, 7)

              if (DatosInvoice[index].VARIOSITEM) {

                if (DatosInvoice[index].VARIOSITEM === "si" || DatosInvoice[index].VARIOSITEM === "SI" && No_lo_Tengo) {
                  ClientesItem.push({ Cliente: DatosInvoice[index].CLIENTE, item: DatosInvoice[index].ITEM })
                  FacturasItem = DatosInvoice.filter((FI: any) => FI.CLIENTE === DatosInvoice[index].CLIENTE && FI.ITEM === DatosInvoice[index].ITEM)

                  for (let Items = 0; Items < FacturasItem.length; Items++) {
                    TotalBase = TotalBase + this.ConvertStringToMoney(FacturasItem[Items].BASE)
                    TotalIva = TotalIva + this.ConvertStringToMoney(FacturasItem[Items].IVA)
                    TotalBaseUSD = TotalBaseUSD + this.ConvertStringToMoney(FacturasItem[Items].BASEUSD)
                    TotalIvaUSD = TotalIvaUSD + this.ConvertStringToMoney(FacturasItem[Items].IVAUSD)
                    DetalleFactura.push({
                      "codoperacion": ID_Json,
                      "descripcion": FacturasItem[Items].DESCRIP,
                      "preciounitario": FacturasItem[Items].BASE,
                      "monto": FacturasItem[Items].BASE,
                    })

                    if (Items === FacturasItem.length - 1) {
                      FacturasItem[0].BASE = this.ConvertMoneyToString(TotalBase)
                      FacturasItem[0].IVA = this.ConvertMoneyToString(TotalIva)
                      FacturasItem[0].NETO = this.ConvertMoneyToString(TotalBase + TotalIva)
                      FacturasItem[0].BASEUSD = this.ConvertMoneyToString(TotalBaseUSD)
                      FacturasItem[0].IVAUSD = this.ConvertMoneyToString(TotalIvaUSD)
                      FacturasItem[0].NETOUSD = this.ConvertMoneyToString(TotalBaseUSD + TotalIvaUSD)
                      FacturasItem[0].DESCRIP = DetalleFactura

                      DataRetorno.push({ ...FacturasItem[0], ID_Json: ID_Json.toString() })
                      ID_Json = ID_Json + 1
                    }

                  }

                } else if (DatosInvoice[index].VARIOSITEM === "no" || DatosInvoice[index].VARIOSITEM === "NO") {
                  DataRetorno.push({ ...DatosInvoice[index], ID_Json: ID_Json.toString() })
                  ID_Json = ID_Json + 1
                }

              } else if (!DatosInvoice[index].VARIOSITEM) {
                DataRetorno.push({ ...DatosInvoice[index], ID_Json: ID_Json.toString() })
                ID_Json = ID_Json + 1
              }
            }

            if (index === DatosInvoice.length - 1) {
              resolve(DataRetorno)
            }

          }

        }

      } catch (error) {
        console.error(error)
      }
    })
  }

  ConvertStringToMoney(Money: any) {
    Money = Money.split('.').join('');
    Money = Money.replace(/,/g, '.')
    return parseFloat(Money)
  }

  ConvertMoneyToString(Money: any) {
    const formatter = new Intl.NumberFormat('de-DE', {
      style: 'currency',
      minimumFractionDigits: 2,
      currency: "USD"
    })
    let NewValor = formatter.format(Money)
    NewValor = NewValor.split('$').join('');
    NewValor = NewValor.split('€').join('');
    NewValor = NewValor.trim()
    return NewValor
  }

  ConvertStringToFecha(Fecha: string) {
    let post = Fecha.indexOf('/');
    const day = Fecha.substring(0, post)
    Fecha = Fecha.substring(post + 1, Fecha.length)
    post = Fecha.indexOf('/');
    const mes = Fecha.substring(0, post)
    const year = Fecha.substring(post + 1, Fecha.length)
    const FechaNew = new Date(`${year}/${mes}/${day}`)
    return FechaNew
  }

}




